.audio-content{
    border: solid 1px gray;
    border-radius: 5px;
    padding: 2px 10px 2px 10px;
    line-height: 1.2;
    width:300px;
}

.audio-interpreter-label{
    font-weight: bold;
    color: #3980BB;
}

.audio-fecha{
    color: black;
}

.audio-btn-transcribir{
    padding: 3px 5px 2px 5px !important;
    font-size: 12px !important;
    width: 90px !important;
    border: 2px !important;
    font-weight: bold !important;
    min-width: 90px !important;
    height: 25px !important;
}

.reproductor-content{
    border: solid 1px gray;
    border-radius: 5px;
    padding: 4px 10px 4px 10px;
    line-height: 1.2;
    width:300px;
    background: #0a5995;
}

.reproductor-interpreter-label{
    font-weight: bold;
    color: white;
}
