.schedule-bottom-margin{
    border-bottom: 1px solid rgba(85, 82, 82, .2);
}

.week-width{
    flex: 21;
}

.closed-tab{
    flex: 0;
    max-width: 0;
}

.open-tab{
    flex: 5;
    max-width: 100%;
}

.no-grow{
    overflow: auto;
}

.cell-day-width{
    min-height: 40px;
    height: 40px;
    flex: 10;
    min-width: auto;
}

.cell-hrs-width{
    min-height: 40px;
    height: 40px;
    flex: 3.3;
    min-width: 30px;
}

.days-of-the-week-row{
    color: rgba(85, 82, 82, .65);
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
}

.hrs-bb{
    border-bottom: 1px solid rgba(85, 82, 82, .2);
}

.cell-border{
    border-left: 1px solid white;
    border-right: 1px solid white;
    border-bottom: 1px solid rgba(85, 82, 82, .2);
}

.current-cell-border{
    background: #0a5995;
    color: white;
    border-left: 1px solid #0a5995;
    border-right: 1px solid #0a5995;
    border-bottom: 1px solid #0a5995;
}

/* .cell-hrs{
    color: rgba(85, 82, 82, .65);
    font-family: Ubuntu;
    font-weight: bold;
    font-size: x-small;
    min-width: 30px;
    text-align: center;
} */

/* .current-day{
    background: #0a5995;
    border-top: 1px solid rgba(85, 82, 82, .2);
    border-bottom: 1px solid rgba(85, 82, 82, .2);
    color: white;
} */


.tab-div-height{    
    height: calc(97vh - 100px);
    
    /*transition: max-width .4s, min-width .4s, max-width .4s;*/
    max-height: calc(97vh - 1px);
}

.tab-transition{
    transition: flex-grow .4s, flex-shrink .4s, flex-basis .4s;
}

.little-tab{
    width: 20px;
    height: 20vh;
    background: #516060;
    cursor: pointer;
}

.little-tab-top{
    width: 20px;
    height: 20px;
    border-top-left-radius: 100%;
    background: #516060;
    cursor: pointer;
}

.little-tab-bottom{
    width: 20px;
    height: 20px;
    border-bottom-left-radius: 85%;
    background: #516060;
    cursor: pointer;
}

/* Calendar cells, hrs and */

.weekly-hrs{
    flex: 3.3;
    font-weight: bold;
    font-size: x-small;
}

.weekly-hrs-daily-view{
    flex: 1.4;
    font-weight: bold;
    font-size: x-small;
}

.cell-height{
    min-height: 40px;
    height: 40px;
}

.cell-hrs{
    color: rgba(85, 82, 82, .65);
    font-family: Ubuntu;
    font-weight: bold;
    font-size: x-small;
    min-width: 30px;
    text-align: center;
}


.day-column{
    flex: 10;
    border-left: 1px solid rgba(85, 82, 82, .2);
    border-right: 1px solid rgba(85, 82, 82, .2);
    /* border-bottom: 1px solid rgba(85, 82, 82, .2); */
    border-bottom: 1xp solid white;
}

.day-column-daily-view{
    flex: 30;
    border-left: 1px solid rgba(85, 82, 82, .2);
    border-right: 1px solid rgba(85, 82, 82, .2);
    /* border-bottom: 1px solid rgba(85, 82, 82, .2); */
    border-bottom: 1xp solid white;
}

.current-day-column{
    flex: 10;
    border-left: 1px solid #0a5995;
    border-right: 1px solid #0a5995;
    border-bottom: 1px solid #0a5995;
}

.schedule-cell-border{
    border-bottom: 1px solid rgba(85, 82, 82, .2);
}


/* Temporal clases for the tests */

.patient-cell-name{
    min-width: max-content;
    color: #555252;
    font-size: small;
}

.cell-green{
    overflow: hidden;
    background: hsla(120, 100%, 75%, 0.3);
    flex: 1;
}

.cell-yellow{
    background: hsla(240, 100%, 75%, 0.3);
    flex: 1;
}

.cell-pink{
    background: hsla(360, 100% , 75%, 0.3);
    flex: 1;
}

.equal-size{
    flex: 1;
}

/*

    Form tab styles

 */

.calendar-tab{
    border-top: 1px solid rgba(85, 82, 82, .3);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: white;
    -webkit-box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.2);
    box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.2);
    margin-bottom: 0.6em;
    height: 100%;
}

.label-text-cell{
    position: absolute;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #516060;
    padding-left: 0.5em;
    font-size: small;
    text-transform: capitalize;
}

.week-month-switch{
    border-radius: .65em;
    border: 2px solid rgba(85, 82, 82, .2);
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
    color: white;
}

.week-montn-button{
    background: white;
    height: 37px;
    min-width: max-content;
    color: #516060;
    padding-left: .8em;
    padding-right: .8em;
    font-family: Ubuntu;
}

.week-montn-button-active{
    border: 2px solid rgba(85, 82, 82, .2);
    background: #0a5995;
    height: 37px;
    min-width: max-content;
    color: white;
    padding-left: .8em;
    padding-right: .8em;
    font-family: Ubuntu;
}

.week-montn-button:hover{
    text-decoration: none;
    color: #516060;
}

.week-montn-button-active:hover{
    text-decoration: none;
    color: white;
}

.button:active{
    background: #044457;
}


/* Tooltip styles */

.top-border-white{
    border-top: 1px solid white;
}

.tooltip-inner {
    max-width: 200px;
    padding: 0rem 0rem !important;
    color: #fff;
    text-align: center;
    background-color: #516060 !important;
    border-radius: 0.25rem;
    font-family: Ubuntu !important;
    min-width: max-content !important;
    /* font-size: small;
    font-weight: bold; */
}

.bold{
    font-weight: bold;
}

.tooltip-app-info{
    font-size: medium;
    text-transform: capitalize;
    min-width: max-content;
}

.tooltip.show {
    opacity: 1 !important;
}

/* .tooltip.show.bs-tooltip-right {
    left: -1rem !important;
} */

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.6rem 0.4rem 0.6rem 0 !important;
    border-right-color: #516060 !important;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.6rem 0 0.6rem 0.4rem !important;
    border-left-color: #516060 !important;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #516060 !important;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #516060 !important;
}

.tab-filter{
    border-top-left-radius: 10px;
    background: #516060;
    color: white;
    height: 3.3em;
}

.width-zero{
    width: 0px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: 0;
}

.filter-tab-input{
    transition: flex-grow .4s, flex-shrink .4s, flex-basis .4s;
}

div#filter-tab-input {
    margin-left: .7em;
    margin-right: .3em;
    background: #516060;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1hwfws3.react-select__value-container.react-select__value-container--has-value div{
    font-weight: bold;
    color: white;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1wy0on6.react-select__indicators div{
    font-weight: bold;
    color: white;
}

div#filter-tab-input div.css-vlouvg.react-select__control{
    border: 0px;
    background: #516060;
}

div#filter-tab-input div.css-vlouvg.react-select__control div.css-1hwfws3.react-select__value-container div.css-1492t68.react-select__placeholder{
    font-weight: bold;
    color: white;
}

div#filter-tab-input div.css-vlouvg.react-select__control div.css-1hwfws3.react-select__value-container div.css-1g6gooi div.react-select__input input{
    caret-color: white;
    font-weight: bold;
}

div#filter-tab-input div.css-vlouvg.react-select__control div.css-1wy0on6.react-select__indicators span.css-d8oujb.react-select__indicator-separator{
    background: transparent;
}

div#filter-tab-input div.css-vlouvg.react-select__control div.css-1wy0on6.react-select__indicators div.css-1ep9fjw.react-select__indicator.react-select__dropdown-indicator{
    color: white;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused{
    box-shadow: none;
    border: 0px;
    background: #516060;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1hwfws3.react-select__value-container div.css-1492t68.react-select__placeholder{
    font-weight: bold;
    color: white;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1hwfws3.react-select__value-container div.css-1g6gooi{
    font-weight: bold;
    color: white;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1hwfws3.react-select__value-container div.css-1g6gooi div.react-select__input{
    color: white;
    font-weight: bold;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1hwfws3.react-select__value-container div.css-1g6gooi div.react-select__input input{
    font-weight: bold;
    color: white;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1wy0on6.react-select__indicators span.css-d8oujb.react-select__indicator-separator{
    background: transparent;
}

div#filter-tab-input div.css-1u21krd.react-select__control.react-select__control--is-focused div.css-1wy0on6.react-select__indicators div.css-1uq0kb5.react-select__indicator.react-select__dropdown-indicator{
    color: white;
}

div#filter-tab-input div.css-vlouvg.react-select__control div.css-1hwfws3.react-select__value-container.react-select__value-container--has-value div.css-xp4uvy.react-select__single-value{
    font-weight: bold;
    color: white;
}

.pad-but-1{
    padding-right: 2em;
    padding-left: 2em;
}

.pad-but-0{
    padding-right: 0em;
    padding-left: 0em;
}

div#tab-datepicker div div input{
    width: 100%;
    height: 1.8em !important;
}

div#tab-datepicker div div div div div div div.DayPicker-Month{
    font-size: x-small;
    font-family: Ubuntu;
}

div#doctor-tab-input{
    height: 1.8em;
    width: 100%;
}

div#doctor-tab-input div.css-vlouvg{
    /* height: 1.8em; */
    min-height: 1.8em !important;
}

div#doctor-tab-input div.css-vlouvg div{
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 5px;
}

div#doctor-tab-input div.css-vlouvg div div.css-1492t68.react-select__placeholder{
    max-height: 1.8em;
    font-size: x-small;
}

.no-error-message div div div.invalid-feeback{
    background: #0a5995 !important;
}

.locked-appointments-table{
    border-radius: 15px;
    border: 2px solid rgba(85, 82, 82, .2);
}

.locked-appointments-table-style{
    border-top-left-radius: 11px;
    border-top-right-radius: 11px;
}

.date-lock-input div div input{
    width: 8em;
}

.tooltip-add-icon{
    width: 1.2em;
    height: 1.2em;
    cursor: pointer;
}

.modal-tooltip{
    background: #516060;
    color: white;
}
