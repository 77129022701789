.oc_costo{
    width: 80px !important;
    border-radius: 5px;
    border: solid 2px #0a5995;
    text-align: right !important;
    padding-right: 4px;
    outline: none;
    color: black;
}

.oc_icon{
    width:35px;
    height: 35px;
    padding: 5px;
    cursor: pointer;
    border-radius: 5px;
}

.oc_icon_azul{
    background: #0a5995;

}

.oc_icon_verde{
    background: #459058;

}

.oc_icon_rojo{
    background: #b93535;

}

.oc_icon_gris{
    background: #516060;

}

.oc_icon_blanco{
    background: white;

}

.oc_icon_azul_2{
    background: #60C579;
    border: solid 2px  #60C579;
}

.oc-text-icon{
    font-weight: bold;
    color: white;
    font-size: 10px;

}

.oc-icon-content{
    border-radius: 5px;
    padding: 4px;
    line-height: 1em;
    height: 70px !important;
    width: 60px !important;
}

.contorno{
    border: solid 2px pink;
}

.oc-total-pago{
    font-size: 18px;
    font-weight: bold;
    color: #0a5995;
}

.oc-input-modal{
    width: 50% !important;
}

.oc-select-modal{
    /*width: 100% !important;*/
    width: 200px !important;
}

.oc-body-form{
    border-top: solid 2px white !important;
}

.oc-background-label{
    background: #515f5f !important;
}

.oc-white-border{
    border: solid 2px white;
}

.oc-custom-table{
    padding: 0px !important;
}

.oc-custom-table-middle{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

.oc-total-price{
    font-size: 20px;
}

.purchase-order-center-title{
    text-align: center;    
}

.w-70{
    width: 70% !important;
}

.w-75{
    width: 75% !important;
}

.w-80{
    width: 80% !important;
}