.patient-tab-left{
    border-top-left-radius: 10px;
    cursor: pointer;
    border: none;
    background: transparent;
}

.patient-tab-right{
    border-top-right-radius: 10px;
    cursor: pointer;
    border: none;
    background: transparent;
}

.patient-section-tab{
    background: #0a5995;
    color:white;
}
