.date-picker-custom {
    border-radius: 1em;
    input {
        border-radius: 1em;
        border: 0.1em solid #cacaca;
        display: block;
        width: 100%;

        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        outline-color: transparent !important;


        border-radius: 3em;
        border: 0.15em solid #dcdcdc;
        /* height: 2.3em; */
        width: 17em;
        height: calc(2.25rem + 2px);
    }
    input:focus {
        border: 0.1em solid #0a5995;
        box-shadow: none;
        -webkit-box-shadow: none;
    }
    input:active {
        border: 0.1em solid #0a5995;
    }
}

.date-picker-custom-overlay {
    position: absolute;
    background-color: #FFF;
    z-index: 1;
    border-radius: 0.5em;
    border: 0.1em solid #cacaca;
}
.DayPicker-Caption > div {
    text-transform: capitalize;
}
.DayPicker-Day {
    padding: 0.2em;
}
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: blue;
}
