.content-input{
    border: solid 2px purple !important;
}

#table-tarjet{
    border-radius: 10px !important;
}

#table-tarjet .thead-tarjet{
    background: #516060 !important;
    margin-bottom: 50px !important;
}

#table-tarjet .form-control{
    max-width:170px !important;
}

#add-row{
    border:none !important;
}

#add-row td{
    height: 75px !important;
}
