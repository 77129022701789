.recuadro{
    border: solid 2px orange !important;
}

.tab-payment{
    border-left: solid 1px gray;
    border-top: solid 1px gray;
    border-right: solid 1px gray;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 45px;
    padding:5px;
    cursor: pointer;
    font-weight: bold;
}

.tab-comodin{
    border-bottom: solid 1px gray;
    z-index: 4;
    border-bottom-left-radius: 15px;
    height: 7px !important;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

.first-active{
    border-top-left-radius: 0px !important;
}

.tab-payment-content{
    margin-top: -6px;
    border-top: solid 1px white;
    border-left: solid 1px gray;
    border-right: solid 1px gray;
    border-bottom: solid 1px gray;

    border-radius: 10px;
    border-top-right-radius: 5px;
    height: 80%;
}

.w-30{
    width: 30%;
}

.b-white{
    background: white;
}

.content-method-payments{
    padding: 2px 25px 2px !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.authorization-number{
    font-size: 10px !important;
    display: block;    
}

.bank-transaction-view{
    border-radius: 100%;
    background: #60c579;
    height: 1.4em;
    width: 1.4em;
    background-image: url("../../../../assets/img/icons/view-icon.png");
    background-size: 100%;
}

.back-blue-arrow{
    height: 1.8em;
    width: 1.8em;
    background-image: url("../../../../assets/img/icons/back-blue-arrow.png");
    background-size: 100%;
}

.btn-historial{
    margin-left: -150px !important;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
    margin-top: 50px !important;
}
