.history-icon{
    border-radius: 100%;
    background: #60c579;
    padding: 0.2em;
}

.table-payments-list thead tr th{
    background: white !important;
    color: black;
    font-weight: bold;
}

.input-back-date input{
    width: 8em;
    text-align: center;
 }
