@import "configs/color";
@import "configs/_custom_color.css";
@import "../../node_modules/react-bootstrap-table/dist/react-bootstrap-table.min.css";
@import "../../node_modules/react-day-picker/lib/style.css";
@import "bootstrap_reset.css";
@import "_custom_buttons.css";
@import "_custom_inputs.css";
@import "_custom_table.css";
@import "styles.css";
@import '../../node_modules/antd/dist/antd.css';

@import "impresion";

body {
    background-color: #f3f3f3;
    text-transform: uppercase; /*add*/
}

.container {
    font-family: Ubuntu,Helvetica,Arial,sans-serif;
    font-size: 16px;
    margin-left: 60px;
    margin-right: 60px;
}
@media only screen and (max-width: 768px) {
    .container {
    margin-left: 0;
    margin-right: 0;
    }
}

.content-wrapper {
    position: absolute;
    top: 76px;
    width: 100%;
}

h1, h2 {
    font-family: Ubuntu;
}

/*Style preloader*/
.loader-container{
    min-height: 140px;
}
.loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 60px;
    height: 60px;
    left: 50%;
    background-color: #e0e0e0;
    position: absolute;
    margin: 1rem 1rem 1rem -30px;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
}
.small-loader {
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 30px;
    height: 30px;
    background-color: #e0e0e0;
    position: absolute;
    padding: 1rem;
    -webkit-animation: spin 1.5s linear infinite; /* Safari */
    animation: spin 1.5s linear infinite;
    margin: 1rem 1rem 1rem -30px;
    left: 40%;
}

/* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.w-40{
    width: 40 !important;
}