.SingleDatePicker {
    width: 100% !important;
}
.SingleDatePickerInput {
    display: block !important;
}
.SingleDatePickerInput__withBorder {
    border: solid 2px #ced4da;
}
.DateInput {
    width: 100% !important;
}
.DateInput_input__focused {
    border-bottom: 2px solid transparent !important;
    /*border: 1px solid blue;*/
}
.CalendarMonth_caption select{
    font-family: "Nunito Regular" !important;
}
.DateInput_input {
    font-family: "Nunito Regular" !important;
    padding: .4375rem .75rem;
    font-size: .8125rem;
    height: auto;
    line-height: normal;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    /*background: blue;*/
    /*border: 1px double blue !important;*/
    color: #fff;
}
button.DayPickerKeyboardShortcuts_buttonReset { display:none; }

.SingleDatePickerInput{
    border: solid 2px #ced4da !important;
}

.CalendarDay__selected{
    background: #df1d38 !important;
    font-weight: bold;
}
