.consult-card{
    background: white;
    height: 740px !important;
    overflow: scroll;
    width: 80%;
    padding: 20px;
}

.medical-history-tab{
    width: 33%;
    height: 50px;
    border: solid 2px gray;
    background: white;
    font-weight: bold;
    cursor: pointer;
}

.medical-history-tab-active{
    background: #0a5995;
    color: white;
}

.surgery-history-table{
    border: none !important;
    width: 90% !important;
}

.surgery-history-table thead tr :first-child{
    border-top-left-radius: 10px !important;
}

.surgery-history-table thead tr :last-child{
    border-top-right-radius: 10px !important;
}

.w-90{
    width: 90% !important;
}

/* Consult Card */
.consult-card-patient-info{
    background: gray;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    padding-right: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
    font-weight: bold;
}

.consult-card-patient-underlined{
    border-bottom: solid 2px white;
}

.consult-card-underlined{
    border-bottom: solid 2px #B5B6B7;
}

.consult-card-underlined-transparent{
    border-bottom: solid 2px transparent;
}

.w-5{
    width: 5%;
}

.w-10{
    width: 10%;
}

.w-15{
    width: 15%;
}

.w-20{
    width: 20%;
}

.w-30{
    width: 30%;
}

.w-40{
    width: 40%;
}

.w-60{
    width: 60%;
}

.w-85{
    width: 85%;
}

.w-90{
    width: 90%;
}

.consult-card-height{
    height: 45px;
}

.diagnostic-card-ttt{
    border: none;
    outline: none;
    /*height: 650px;*/
    height: 400px;
    width: 100%;
    text-align: justify;
    font-size: 17px;
    resize: none;
}

/* Diagnostic Card */
.diagnostic-card-container{
    background: white;
    /*
    height: 700px !important;
    overflow: scroll;
    outline: none;
    */
}

.gonloscopia_x{
    font-size: 40px;
    font-weight: bold;
}

.carousel__dot-group button{
    background: white;
    border: solid 1px black;
    border-radius: 15px;
    width: 15px;
    height: 15px;
    margin-left: 10px;
}

.carousel__dot-group .carousel__dot--selected{
    background: black;
    border: none;
}

.contenedor-card{
    width: 100% !important;
    min-width: 800px !important;
}

.contenedor-hoja{
    width: 100% !important;
    min-width: 800px !important;
    margin-bottom: 75px;
    display: contents;
}

.menu-histori {
    width: 75%;
}

@media only screen and (max-width: 768px) {
    .menu-histori {
        width: 100%;
    }
}
.paginacion-histori{
    img {
        cursor: pointer;
    }
}

.table-detail-lhs {
    tr {
        .title-table {
            width: 40%;
        }
        .subtitle-table {
            text-align: center;
        }
    }
}
.row-title {
    font-weight: bold;
}
.table-detail-lhs_1 {
    tr {
        border-bottom: none;
    }
    .tr-space {
        height: 20px;
    }
    .title {
        text-align: center;
    }
    .separador {
        width: 5%;
        text-align: center;
        font-size: 15px;
        font-weight: bold;
    }
    .description {
        text-align: center;
        width: 20%;
        border-bottom: 1px solid #ccc;
    }
}