.reporte-consulta-menu-content{
    overflow-x: auto;
    min-height: 75px;
}

.reporte-consulta-item{
    padding: 10px;
    background: white;
    color: blue;
    border:solid 2px gray;
}

.consult-report-return{
    font-weight: bold;
    color: #0a5995;
    font-size: 20px;
}

/*
    Link para el slider component:
    https://codesandbox.io/s/k0fxi?fontsize=14
*/

.scroll-menu-item {
    padding: 10px 5px 10px;
    background: white;
    color: #516060;
    font-weight: bold;
    border:solid 2px gray;
    cursor: pointer;
}

.scroll-menu-item-wrapper.active {
    border: 1px blue solid;
}

.scroll-menu-item.active {
    background: #0a5995;
    color: white;
    font-weight: bold;
}

.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 25px;
}
