.card-form{
    width: 85%;
    padding-top: 3rem;
}

.big-card-form{
    width: 85%;
    padding-top: 1rem;
}

.form-p {
    padding: 2em;
}

.user-name-display label{
    color: white;
    font-size: large;
    margin-right: 0.5em;
    margin-bottom: 0em;
}

.user-name-display img{
    width: 35px;
    height: 35px;
}
/* Estilos para dropdown de usuario */
.dropdown.show.user-name-display .dropdown-toggle{
    background-color: #516060;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.user-name-display .dropdown-toggle:hover {
    background-color: #516060;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.user-name-display .dropdown-toggle {
    padding: 0px 10px 0px 10px;
    border-radius: inherit;
    background-color: #df1d38;
    display: flex;
    align-items: center;
}
.user-name-display .dropdown-menu a button:hover {
    background-color: #516060;
    color: white;
}

label {
    font-family: Ubuntu;
    font-size: medium;
    font-weight: bold;
    color: #7f7f7f;
}

link, button{
    font-family: Ubuntu;
    font-size: medium;
    font-weight: bold;
}

.tab-background-blue{
    background-color: #0a5995;
}

.tab-background{
    background-color: #f3f3f3;
}

.tab-br-bl{
    border-bottom-left-radius: .7em;
}

.tab-br-tr{
    border-top-right-radius: .7em;
}

.tab-br-br{
    border-bottom-right-radius: .7em;
}

.tab-br-tl{
    border-top-left-radius: .7em;
}

.tab {
    display: block;
    width: 100%;
    height: 40px;
    margin: 0;
    font-family: Ubuntu;
    font-size: larger;
    font-weight: bold;
    color: white;
    background-color: #0a5995;
}

.tab:hover {
    color: white;
    text-decoration: none;
}

.tab-active {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    font-family: Ubuntu;
    font-size: larger;
    font-weight: bold;
    color: #516060;
    background-color: #f3f3f3;
}

.tab-active:hover {
    color: #516060;
    text-decoration: none;
}

.patient-down-icon div {
    height: 1.3em;
    width: 1.3em;
    border-radius: 100%;
    border: 2px solid #7f7f7f;
    background-position: center;
    background-image: url("../assets/img/icons/arrow-down.svg");
    background-size: 75%;
    background-repeat: no-repeat;
}

.search-patient-button{
    width: 23em;
}

.patient-up-icon div {
    height: 1.3em;
    width: 1.3em;
    border-radius: 100%;
    border: 2px solid #7f7f7f;
    background-position: center;
    background-image: url("../assets/img/icons/arrow-up.svg");
    background-size: 75%;
    background-repeat: no-repeat;
}

.react-switch-bg {
    height: 24px !important;
    width: 52px !important;
    div{
        height: 100% !important;
    }
}

div#normal-switch {
    width: 22px !important;
    height: 22px !important;
}

.react-switch-handle{
    box-shadow: none !important;
}

.switch-label{
    font-weight: normal !important;
}


/* SCROLLBAR STYLES */


::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #7f7f7f;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555252;
}
::-webkit-scrollbar-thumb:active {
  background: #555252;
}
::-webkit-scrollbar-track {
  background: #f3f3f3;
  border: 0px none #ffffff;
  border-radius: 100px;
}
::-webkit-scrollbar-track:hover {
  background: #f3f3f3;
}
::-webkit-scrollbar-track:active {
  background: #f3f3f3;
}
::-webkit-scrollbar-corner {
  background: transparent;
}
.table-input {
    tr {
        border: none;
    }
    td {
        text-align: center;
        padding: .25rem;
    }
    th {
        text-align: center;
        color:#0a5995;
        font-width: bold;
    }
    input {
        width: 80%;
        margin: auto;
    }
    .form-control {
        width: 10rem;
        margin: auto;
    }
}

.other-table-input{
    tr {
        border: none;
    }
    td {
        text-align: center;
        padding: .25rem;
        border: none;
    }
    th {
        text-align: center;
        color:#0a5995;
        font-width: bold;
    }
    input {
        width: 80%;
        margin: auto;
    }
    label{
        font-size: 12px !important;
    }
}

.table-input-sm {
    tr {
        border: none;
    }
    td {
        text-align: center;
        padding: .25rem;
    }
    th {
        text-align: center;
        color:#0a5995;
        font-width: bold;
    }
    input {
        width: 80%;
        margin: auto;
    }
    .form-control {
        width: 7rem;
        margin: auto;
    }
}

.step_complete {
    color: #33cc33 !important;
}

.div-round{
    border: 1px solid #000!important;

    border-radius: 5px;
}
