@import "./configs/color.css";

/* ---------------- ESTILOS GLOBALES DE IMPRESION ---------------------*/
@page {
    margin: 0.3cm;    
    size: portrait;   
}

@media print {
    body,
    html,
    html body {
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeSpeed;
        color: #000;
        min-width: 0 !important;
        max-height: auto !important;
        -webkit-print-color-adjust: exact;
        background-color: #FFFFFF !important;
        -webkit-print-color-adjust: exact;
    }
    .dont-print {
        display: none !important
    }
    #PRINT-ROOT-CONTAINER {
        font-size: 11px;
        display: block !important;
    }

    .contenedor-receta{
        display: block;
    }

    .contenedor-transcripcion{
        display: block;
    }

    .show-only-on-print{
        visibility: visible !important;
    }
    /* La parte comentada habilita el pie de pagina para la transcripción
       sin embargo debido al margen global de las impresiones se sobrepone
    */
    
    /*
    .contenedor-transcripcion .footer-transcription{
        position: fixed;
        bottom: 0;
        width: 90%;
        height: 90px;
        left: 50px !important;        
        opacity: 0.4;  
    } 
    */       
}

#PRINT-ROOT-CONTAINER {
    display: none;
}

/* ---------------- ESTILOS PERSONALIZDOS DE IMPRESION ---------------------*/

.print-shadow {
    background-color: white;
    position: relative;
    padding: 1cm 1.5cm;
    -webkit-print-color-adjust: exact;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.print-space {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.row-codigos {
    height: 32mm;
}
.row-codigos:first-child {
    height: 28.5mm;
}
.item-codigo {
    width: 140px;
    height: 80px;
}
.container-item-codigo {
    width:43mm;
    height: 100%;
}
.container-item-codigo:first-child {
    padding-right: 20px;
}
/* .container-item-codigo:last-child {
    padding-left: 40px;
} */
/* .item-codigo:first-child {
    margin-right: 20px;
    margin-left: 0;
}
.item-codigo:last-child {
    padding-left: 5mm;
    width: 47.5mm;
    margin-right: 0;
} */



.div-test {
    width: 215.9mm ;
    height: 27.94cm;
    /* background-color: lightgrey; */
    -webkit-print-color-adjust: exact;
}

.hoja-carta {
    width: 215.9mm ; 
    height: 27.94cm;
    padding: 0 !important;
    margin-bottom: 0.6cm;
    display: inline-block;
    font-size: 0.6em;
    background-color: white;
}

.w-hoja-carta {
    width: 215.9mm;
}
.pagina-carta {
    background-color: white;
    /*overflow: hidden;*/
    position: relative;
    padding: 1cm 1.5cm;
    -webkit-print-color-adjust: exact;
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Vista de impresion */

.print-only {
    display: none !important;
}

/* Utils */
.imp-pt-1 {
    padding-top: 1em;
}
.imp-pt-2 {
    padding-top: 2em;
}
.imp-pb-2 {
    padding-bottom: 2em;
}
.imp-pl-20 {
    padding-left: 20px;
}
.imp-pr-20 {
    padding-right: 20px;
}

.no_pagina {
    position: absolute;
    bottom: 1%;
    width: 100%;
}

/* Tabla en imporesion */
.impresion-tabla {
    padding: 0 20px;
}

.salto-pagina {
    page-break-before: always;
}
.salto-pagina-despues {
    page-break-after: always;
}

/* VISTA PREVIA RECIBO 80mm */
.vista-recibo-80mm {
    padding: 5mm;
    width: 80mm;
}

@media print {
    .sidebar,
    .topnavbar,
    .offsidebar,
    .nav-justified,
    .nav-tabs.nav-justified,
    .toolbar,
    .react-tabs__tab-list,
    .rc-tabs-bar,
    .breadcrumb,
    .styles_closeIcon__1QwbI,
    footer,
    .btn {
        display: none !important;
        width: 0 !important;
        height: 0 !important;
    }
    .react-tabs__tab-panel--selected {
        border: none !important;
    }
    .react-tabs__tab-panel--selected {
        margin: 0 !important;
        padding: 0 !important;
    }
    .content-wrapper {
        left: 0 !important;
        top: 0 !important;
        right: 0 !important;
        padding: 0;
    }
    .no-print {
        display: none !important;
    }
    .print-only {
        display: block !important;
    }
    .print {
        display: block !important;
    }
    .contrato-container {
        padding: 0;
    }
    .no-print-margin {
        margin: 0 !important;
    }
    .no-print-padding {
        padding: 0 !important;
    }
    .react-bs-table-container .table-bordered {
        min-width: 21cm !important;
    }
    .print-shadow {
        padding: 0cm !important;
        box-shadow: none;
    }
    .hoja-carta {
        overflow: hidden;
        padding: 0 !important;
        margin: 0 0 0 0;
        width: 215.9mm !important;
        height: 279.2mm !important;
        margin-bottom: 0;
        font-size: inherit !important;

        /* temporales */
        -webkit-print-color-adjust: exact;
        /* background-color: lightcoral; */
    }

    .no_padding {
        padding: 0 !important;
    }

    .no-margin {
        margin: 0 !important;
    }

    /* .hoja-carta:first-child{
        margin: 0cm 0 0 0;
        height: 280.8mm !important;
    }
    .hoja-carta:last-child{
        margin: 0cm 0 0 0;
        height: 280.9mm !important;
    } */
    /*.print-scale{
        width: 21.6cm;
        padding: .2cm;
        font-size: 14px;
    }*/

    /* Utils */
    .imp-pl-20 {
        padding-left:20px;
    }
    .imp-pr-20 {
        padding-right: 5px;
    }
    .no_pagina {
        bottom: 0%;
    }
    .print-space {
        display: block;
        background-color: white;
        -webkit-print-color-adjust: exact;
    }
    .impresion-venta {
        background-color: white;
        -webkit-print-color-adjust: exact;
    }

    .vista-recibo-80mm {
        padding: 0mm !important;
        width: 100%;
    }
}

@media only screen and (max-width: 768px) {
    .print-space {
        align-items: flex-start;
        overflow-x: auto;
    }
}