.calendar-date-label{
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
    color: #516060;
}

.brb{
    border-radius: .3em;
}

.br-right{
    border-top-right-radius: .5em;
    border-bottom-right-radius: .5em;
}

.br-left{
    border-top-left-radius: .5em;
    border-bottom-left-radius: .5em;
}

.button{
    background: #0a5995;
    height: 37px;
    min-width: max-content;
    color: white;
    padding-left: .8em;
    padding-right: .8em;
    font-family: Ubuntu;
}

.button:hover{
    text-decoration: none;
    color: white;
}

.button:active{
    background: #044457;
}

.button-gray{
    background: #555252;
    height: 37px;
    min-width: max-content;
    color: white;
    padding-left: .8em;
    padding-right: .8em;
    font-family: Ubuntu;
}

.button-gray:hover{
    background: #555252;
    text-decoration: none;
    color: white;
}

.button-gray:active{
    background: #555252;
}

/* Calendar title row */

.div-as-margin-title{
    flex: 2.7;
    background: #516060;
}

.calendar-title-item{
    height: 45px;
    flex: 12;
    background: #516060;
    min-width: max-content;
    color: white;
    padding-left: 1em;
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
}

.calendar-title-item-small{
    flex: 8;
    background: #516060;
    height: 45px;
    min-width: max-content;
    color: white;
    padding-left: 1em;
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
}

/* Daily schedule row */

.div-as-margin{
    flex: 2.7;
    padding-top: .3em;
    min-height: 30px;
    /* color: #516060; */
    color: rgba(85, 82, 82, .65);
    font-family: Ubuntu;
    font-weight: bold;
    font-size: x-small;
    border-right: 1px solid rgba(85, 82, 82, .2);
    text-align: center;
}

.schedule-row-item{
    flex: 12;
    min-height: 35px;
    padding-left: 1em;
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
    color: #516060;
    border-top: 1px solid rgba(85, 82, 82, .2);
}

.schedule-row-item-small{
    flex: 8;
    min-height: 35px;
    padding-left: 1em;
    font-family: Ubuntu;
    font-weight: bold;
    font-size: small;
    color: #516060;
    border-top: 1px solid rgba(85, 82, 82, .2);
}

.action_space{
    margin-left: .8em;
    margin-right: .8em;
}

/* .appointment-color-1{
    background: hsla(197.20000000000005, 95.2%, 73.2%, 0.2);
}

.appointment-color-1.first{
    border-left: 3px solid #06aff3;
}

.appointment-color-2{
    background: hsla(359.70000000000005, 88.9%, 80.9%, 0.2);
}

.appointment-color-2.first{
    border-left: 3px solid #f22122;
}

.appointment-color-3{
    background: hsla(35.30000000000001, 88.9%, 80.9%, 0.2);
}

.appointment-color-3.first{
    border-left: 3px solid #f29c21;
}

.appointment-color-4{
    background: hsla(117.80000000000001, 95.8%, 42.1%, 0.15);
}

.appointment-color-4.first{
    border-left: 3px solid #088c03;
} */

.actions div.check {
    border-radius: 100%;
    background: white;
    height: 1.4em;
    width: 1.4em;
    background-image: url("../../../../assets/img/icons/round-check-icon.png");
    background-size: 100%;
}

.actions div.edit-patient {
    height: 1.2em;
    width: 1.2em;
    background-image: url("../../../../assets/img/icons/edit-icon-blue.png");
    background-size: 100%;
    cursor: pointer;
}

.actions div.delete-patient {
    height: 1.2em;
    width: 1.2em;
    background-image: url("../../../../assets/img/icons/delete-patient-icon.png");
    background-size: 100%;
    cursor: pointer;
}

.actions div.edit-patient-white {
    height: 1.2em;
    width: 1.2em;
    background-image: url("../../../../assets/img/icons/edit-icon.png");
    background-size: 100%;
    cursor: pointer;
}

.actions div.delete-patient-white {
    height: 1.2em;
    width: 1.2em;
    background-image: url("../../../../assets/img/icons/delete-icon.png");
    background-size: 100%;
    cursor: pointer;
}

.action-separator{
    width: 2px;
    background: white;
}

.is-invalid {
    .invalid-feedback{
        display: block !important;
    }
}

.over-auto{
    overflow: auto;
}

.cell-border-black{
    border-left: 3px solid green;
}

.cell-label-hide{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}