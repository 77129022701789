.rcp-table-decoration{

    border: none !important;
    box-shadow: 0px 0px  0px  0px !important;
}

/* For tabas in Component ventas.Services */
.sales-services{
    float: left !important;
}

#left-tab{
    width:55% !important;
    height: 85% !important;
    margin-right: 0px !important;
    margin-left:20px !important;
    overflow: auto !important;
    padding-bottom: 5px !important;
}

#right-tab{
    width:40% !important;
    height: 85% !important;
    margin-right: 0px !important;
    margin-left:20px !important;
}

/* For toolbar-tab */
#toolbar-tab{
    justify-content: right !important;
}

#toolbar-tab .form-control{
    border: none !important;
    outline: none !important;
    background: #DADADA !important;
    border-radius: 0px !important;
    align-items: right !important;
    padding-right: 0px !important;
    /*width:20% !important;*/

}

#toolbar-tab .boton-busqueda{
    background: transparent !important;
    background: transparent !important;
}

#toolbar-tab .contenedor-search{
    width:100% !important;
    padding-right: 15% !important;
    background: #DADADA !important;
}

/* For scroll in the list of services */
.description-table{
    max-height:75% !important;
    height: 75% !important;
    overflow: auto;
    padding-top:20px;
    overflow-x: hidden !important;
}

#description-table tbody{
    border-top: solid 2px white !important;
}

#description-table tr{
    border-top: none !important;
}

#description-table tr td{
    border-top: none !important;
    text-align: left !important;
    padding-left: 25px !important;
}

.sales-cell-service{
    padding-top: 1px !important;
    padding-bottom: 1px !important;
    text-align: left !important;
    vertical-align: middle !important;
}

.table-span{
    font-size:13px !important;
}

.cupon-row{
    color: #26881E;
}

/* For the input group use to up an down cant of products/service*/
.group-button-up-down{
    /*background: #416CBD;*/
    background: white;
    align-content: center;
    text-align: center;
    border-radius: 8px !important;
    height: 30px;
    vertical-align: middle !important;
    display: table-cell;
    border: solid 2px transparent;
}

.group-button-up-down .input-cant{
    background: white;
    border-radius: 3px;
    font-size: 10px !important;
    padding: 0px 2px  0px 2px;
    border: solid 1px #0a5995;
    display: inline-flex;
    height: 25px;
    text-align: center !important;
    font-size: 15px !important;
    width: 60px !important;
}

.group-button-up-down .button-content{
    background:transparent;
    background: #0a5995;
    margin: 0px;
    padding: 5px;
    border:none;
    display: inline-flex;
    height: 22px;
    vertical-align: middle;
}

.group-button-up-down .left-tab{
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}

.group-button-up-down .right-tab{
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.group-button-up-down .arrow-button{
    max-width: 10px;
    color: white !important;
}

.arrow-button-toggle{
    max-width: 10px;
    color: black !important;
}

/* For the total of products/services */
.total-sales{
    border: solid 2px #459058;
    background: #459058;

    /*height: 40px !important;*/

    height: 100px !important;
    /*max-height: 100px !important;*/
    padding-right: 20px !important;
}

.sales-tab{
    text-align: center;
    color: white;
    /*font-weight: bold;*/
    font-size:16px;
    display: table-cell;
    vertical-align: middle;
}

.sales-tab .element-type{
    display: inline-flex;
}

/* For icons circle in sales */
.circle-button-delete{
    width:25px;
    height:25px;
    border-radius: 25px;
    background: transparent;
    cursor:pointer;
    border: solid 1px red;
}

.circle-button-car{
    width:30px;
    height:30px;
    border-radius: 25px;
    background: transparent;
    cursor:pointer;
    border: solid 1px #0a5995;
}

/*  For whidth */
.w-30{
    width:40%;
}

.w-40{
    width:40%;
}

.w-60{
    width:60%;
}

.w-80{
    width:80%;
}

/* Bottom Buttons */
.button-down{
    margin-left:5px;
    background: transparent !important;
    border: solid 2px #516060;
    color: #516060 !important;
    font-size: 12px !important;
    text-decoration: none !important;
    cursor:pointer !important;
}

.button-charge{
    height: 60px;
    vertical-align: middle !important;
    /*line-height:normal !important;*/
}

.button-charge-small{
    height: 25px;
    margin-bottom: 2px;
    vertical-align: middle !important;
    /*line-height:normal !important;*/
}

#payment-title{
    border-bottom: solid 7px #516060 !important;
    padding-top: 5px !important;
    padding-bottom: 5px !important;
}

#table-patient{
    color: #516060 !important;
    font-size:14px !important;
}

#table-patient tr{
    border: none !important;
}

#table-patient tr td{
    text-align: center;
}

#table-patient tr th{
    text-align: left;
}

#table-patient tr td, th{
    border: none !important;
    text-align: left;
}

#modal-input{
    border-radius: 1em !important;
    background: white !important;
    max-height: 50px !important;
}

.sales-tab .element-type{
    width:auto !important;
}

.my-toggle{
    font-size:10px;
    font-weight: bold;
    cursor:pointer;
}

.my-number-format{
    max-height: 25px;
    /* border: solid 2px orange; */
    outline:none;
    font-size:12px !important;
    padding:2px;
    width:25% !important;
    border-radius: 5px;
    border: solid 1px #516060;
}

.my-input-error{
    border: solid 1px red !important;
}

.tab-title{
    border: solid 2px orange !important;
}

.bordeado{
    border:solid 2px red !important;
}

.service-title{
    font-family: Ubuntu;
    font-size: medium;
    font-weight: bold;
    color: #516060;
    margin-bottom: 0.5em;
}

/* For table in selection products section, information patient */
#table-patient{
    margin-top: 10px;
    border:none;
    width: 70%;
}

#table-patient tbody tr, tr td, tr th{
    border:none;
    padding:1px;
    font-size: 13px;
    width:auto;
}

gray-color{
    color: #516060;
}

.b-516060{
    background: #DADADA;
}

.cursor-pointer{
    cursor: pointer;
}

.my-alert-message{
    color:#B24040;
    font-weight: bold;
} 

.title-print-view{
    height: 40px;
    background: #516060;
    color:white;
}

/* ESTILOS DE FACTURA */
.fac-content{
    max-height:100% !important;
    height: 100% !important;
    overflow: auto;
    overflow-x: hidden !important;
}

.fac{
    /*border: solid 1px gray;*/
    border-radius: 10px;
    box-shadow: 5px 5px 3px #888888;
    margin-bottom: 20px;
    font-size: 12px !important;
}

.fac-title{
    font-size: 14px ;
    /*color:  #516060;*/
    color: black;
    /*font-weight: bold;*/
}

.fac-number{
    font-size: 20px;
    color:  black;
    border:dashed 1px #858585;
    border-radius: 5px;
    padding-left: 45px;
    padding-right: 45px;
}

.table-preview thead tr th{
    border: solid 1px white !important;
}

.table-preview tbody tr td{
    border: solid 2px white !important;
}

.fac-title-detail{
    /*font-weight: bold;*/
    /*color: #516060;*/    
    color: black;
}

.fac-title-detail span{
    /*font-size:15px !important;*/
}

.fac-row-detail span{
    font-size: 12px !important;
    /*font-weight: bold;*/
    /*color: #858585;*/
    color: black;
}

.fac-row-discount{
    display: block;
    font-size: 10px !important;
    /*color: #858585;*/
    color: black;
    padding: 0px !important;
    margin: none !important;
}

.space-none{
    padding-bottom:0px !important;
    margin:none !important;
}

.fac-division-line{
    margin-bottom: 0px !important;
    border: dashed 1px #858585;
}

.fac-row-descuento span{
    font-size: 12px !important;
    /*font-weight: bold;*/
    /*color: #858585;*/
    color: black;
    padding-top:0px !important;
    padding-bottom: 0px !important;
    margin:none !important;
}

.fac-row-total span{
    font-size: 12px !important;
    /*font-weight: bold;*/
    /*color: #516060;*/
    color: black;
}

.fac-row-iva span{
    font-size: 12px !important;
    /*font-weight: bold;*/
    /*color: #516060;*/
    color: black;
}

.fac-row-descuento td, .fac-row-total td, .fac-row-iva td{
    padding-top:0px !important;
    padding-bottom: 0px !important;
    margin: none !important;
}

.fac-footer{
    font-size: 12px !important;
    /*font-weight: bold;*/
    /*color: #858585;*/
    color: black;
}

/* Para las cuentas abiertas */
.account{
    border: solid 2px #043357;
    padding: 7px;
    color: #043357;
    margin-right: 5px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 12px;
    cursor:pointer;
}

.cancel-account{
    border: solid 2px #b93535;
    padding: 7px;
    color: #b93535;
    margin-right: 5px;
    font-weight: bold;
    border-radius: 5px;
    font-size: 12px;
    cursor:pointer;
}

.fuente-boton-deposito{
    font-size: 12px !important;
}

.icon-service-button{
    width: 30px !important;
    height: 30px !important;
    margin: auto !important;
    cursor: pointer !important;
}

.button-service-icon{
    background: none !important;
    border: none;
    text-align: center;
    margin: auto;
}

.w-85{
    width: 85% !important;
}

.negro{
    color: black !important;
}

.w-90{
    width: 90%;
}
