.content-canvas-button{
    background: #459058;
    width: 40px;
    height: 25px;
    padding: 2px 5px 2px;
    border-radius: 5px
}
.icon-canvas-button{
    width: 20px;
    height: 20px;
    margin: auto;
    display: block;
}
.input-new .form-control{
    width: 150px !important;
}