.h-10{
    min-height: 10% !important;
    height: 10% !important;
}

.h-15{
    min-height: 15% !important;
    height: 15% !important;
}

.w-25{
    width: 25% !important;
}

.tab-bodega{
    border-radius: 7px;
    height: 55px;
    min-height: 55px;
    color:white;
    z-index: 1;
}

.tab-bodega-blue{
    background: #0a5995;
}

.border-blue{
    border: solid 2px#0a5995;
}


.tab-bodega-green{
    background: #04A412;
}

.border-green{
    border: solid 2px #04A412;
}

.tab-bodega-yellow{
    background: #E28736;
}

.border-yellow{
    border: solid 2px#E28736;
}

.bodega-tab-circle-content{
    margin-right: -15px;
    z-index: 2;
    background:#f3f3f3 !important;
    width: auto !important;
    height: auto !important;
}

.bodega-tab-circle{
    padding:6px;
    width: 55px;
    height: 55px;
    min-width: 55px;
    min-height: 55px;
    background: white;
}

.bodega-tab-icon{
    width: 25px;
    height: 25px;
}

.bodega-subtitulo{
    font-size: 13px !important;
}

.bodega-flechita{
    text-decoration: none;
    font-weight: bold;
    font-size: 20px;
    color: white;
}

.bodega-flechita:hover{
    color:white;
    text-decoration: none;
}

.ingreso-textarea{
    width: 100% !important;
    height: 50px !important;
    border: solid 2px #cacaca !important;
    outline: none;
    padding:3px !important;
    padding-left: 10px !important;
    border-radius: 15px;
}

.ingreso-textarea:focus{
    border-color: #0a5995 !important;
}

/* Para los espacios inferiores de trabajo */
#left-tab-bodega{
    width:35% !important;
    height: 85% !important;
    margin-right: 0px !important;
    margin-left:50px !important;
    padding-bottom: 5px !important;
}

#right-tab-bodega{
    width: 55% !important;
    height: 85% !important;
    margin-right: 0px !important;
    margin-left:20px !important;
    padding-top: 0px !important;
    /*overflow: hidden !important;*/
}

.content-table-bodega{
    overflow: auto !important;
    height: 50% !important;

}

.thead-table-ingreso-bodega{
    background:#6c757d !important;
    min-height: 150px !important;
}


.table-prueba tbody{
    background: yellow;
    height: 150px !important;
}

.customClass td, .customClass th{
    padding-left: 10px !important;
    padding-right: 5px !important;
    text-justify: inter-word;
    text-align: center !important;
}

.customClass{
    z-index: -50 !important;
}

.bodega-ingreso-footer{
    height: 50px;
    min-height: 50px;
    background:#343a40;
}

.element-footer-bodega{
    color: white;
    font-weight: bold;
    width:100px;
}

.input-ingreso-bodega-lote{
    width: 75px;
    border-radius: 5px;
    border: 0.15em solid #cacaca;
}

.date-pricker-ingreso{
    background: orange !important;
    font-size: 10px !important;
}

.text-red{
    color: red;
    font-size: 10px;
}

.link-tab-bodega-admin{
    color:white !important;
    text-decoration: none !important;
}

.ingreso-bodega-number-format{
    max-width: 75px !important;
    border-radius: 5px;
    border: 0.15em solid #cacaca;
}

.notification-whearhouse-icon{
    width: 20px;
    height: 20px;
}

.title-list-iventory{
    font-weight: bold;
    font-family: ubuntu;
    font-size: 1.6em;
    color: #516060;;
}

.link-sin-formato{
    text-decoration: none !important;
}

.tab-background-readjustment div{
    background: #E28736 !important;
}

.tab-background-low div{
    /*background: #E28736 !important;*/
    background: #04A412 !important;
}

.barcode-service-list{
    font-size: 10px !important;
    color: black !important;
}
