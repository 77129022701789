.main-background{
    background: #f3f3f3;
    height: 100vh;
}

.white-text{
    color: white !important;
}

.header {
    width: 100%;
    height: 60px;
    background: #df1d38;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
    -moz-box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
    box-shadow: 0px 3px 3px 0px rgba(0,0,0,0.25);
    z-index: 999;
}

.header img{
    height: 35px;
    width: 157px;
    margin: 0.8em 0.7em 0.8em 0.7em;
}

.rel-container {
    position: relative;
    min-height: 664px;
    z-index: 995;
    height: calc(100vh - 60px);
}

.menu-column {
  
}

.menu-column a{
    display: block;
    white-space: nowrap;
    min-width: fit-content;
    min-width: -moz-fit-content;
    min-width: -webkit-fit-content;
    height: 3.1em;
    padding-left: calc(30px - 1.5em);
    margin: 0;
    font-family: Ubuntu;
    font-size: small;
    font-weight: bold;
    color: #516060;
}

.menu-column a:hover{
    background: #516060;
    /* background-image: linear-gradient(to right, #b93535 , #df1d38); */
    color: white;
    text-decoration: none;
}

.menu-column a:focus{
    /* background-image: linear-gradient(to right, #b93535 , #df1d38); */
    background: #516060;
    color: white;
    text-decoration: none;
}

.submenu-active {
    background: #dcdcdc;
    text-decoration: none;
}



.submenu-active div{
    height: 1.5em;
    width: 0.4em;
    margin-right: 0.6em;
    background-image: url("../../../../assets/img/icons/bar-icon.png");
    background-size: 100% 100%;
}

.submenu{
    background: #dcdcdc;
    padding-left: 2.3em !important;
    text-decoration: none;
}

.submenu:hover{
    background: #dcdcdc !important;
    color: #516060 !important;
}

.submenu:focus{
    background: #dcdcdc !important;
    color: #516060 !important;
}

.active {
    background: #516060;
    /* background-image: linear-gradient(to right, #b93535 , #df1d38); */
    color: white !important;
    text-decoration: none;
}

.nav-icon {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background-size: 100%;
    justify-content: center;
}

.nav-icon .icon {
    width: 50%;
    height: 50%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.home-icon {
    background-image: url("../../../../assets/img/icons/home-icon.png");
}

.home-icon:focus {
    background-image: url("../../../../assets/img/icons/home-icon-white.png");
}

.home-icon-active {
    background-image: url("../../../../assets/img/icons/home-icon-white.png");
}

.patients-icon {
    background-image: url("../../../../assets/img/icons/patients-icon.png");
}

.patients-icon:focus {
    background-image: url("../../../../assets/img/icons/patients-icon-white.png");
}

.patients-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/patients-icon-white.png");
}

.users-icon {
    background-image: url("../../../../assets/img/icons/users-icon.png");
}

.users-icon:hover div {
    background-image: url("../../../../assets/img/icons/users-icon-white.png");
}

.users-icon:focus div {
    background-image: url("../../../../assets/img/icons/users-icon-white.png");
}

.users-icon-active div {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/users-icon-white.png");
}

.accounts-icon {
    background-image: url("../../../../assets/img/icons/accounts-icon.png");
}

.accounts-icon:hover div {
    background-image: url("../../../../assets/img/icons/accounts-icon-white.png");
}

.accounts-icon:focus div {
    background-image: url("../../../../assets/img/icons/accounts-icon-white.png");
}

.accounts-icon-active div {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/accounts-icon-white.png");
}

.cash-box-icon {
    background-image: url("../../../../assets/img/icons/cash-box-icon.png");
}

.cash-box-icon:hover div {
    background-image: url("../../../../assets/img/icons/cash-box-icon-white.png");
}

.cash-box-icon:focus div {
    background-image: url("../../../../assets/img/icons/cash-box-icon-white.png");
}

.cash-box-icon-active div {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/cash-box-icon-white.png");
}

.institutions-icon {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    background-image: url("../../../../assets/img/icons/institution-icon.png");
    background-size: 100%;
}

.institutions-icon:hover div {
    color: white !important;
    background-image: url("../../../../assets/img/icons/institution-icon-white.png");
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: white !important;
    text-decoration: none;
}

.institution-icon:focus div {
    background-image: url("../../../../assets/img/icons/institution-icon-white.png");
}

.institutions-icon-active div {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/institution-icon-white.png");
    background-size: 100%;
}

nav#side-nav {
    background: #f3f3f3 !important;
    box-shadow: 1px 0px 24px #888;
    z-index: 999999;
    bottom: unset;
}

nav#side-nav > button span {
    background-color :#df1d38;
}

nav#side-nav .side-nav__text {
    color: #808a8a;
}

nav#side-nav .side-nav__text:hover {
    color: #516060;
}

.content {
    background: #f3f3f3;
    position: relative;
    left: 64px;
    width: calc(100% - 64px);
    vertical-align: middle;
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
    padding: 0 1.5em 1.5em 2.5em;
    transition: width .15s, margin .15s;
}

.content-min {
    margin-left: 176px;
    width: calc(100% - 240px);
}

.content-with-tabs {
    background: #f3f3f3;
    position: relative;
    left: 64px;
    width: calc(100% - 64px);
    vertical-align: middle;
    padding-bottom: 1.5rem;
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
    transition: width .15s, margin .15s;
}

.content-with-tabs-min {
    left: 240px;
    width: calc(100% - 240px);
}

.no-border{
    border: none;
}

.configuration-icon {
    background-image: url("../../../../assets/img/icons/configuration-icon.png");
}

.configuration-icon:hover {
    background-image: url("../../../../assets/img/icons/configuration-icon-white.png");
}

.configuration-icon:focus {
    background-image: url("../../../../assets/img/icons/configuration-icon-white.png");
}

.configuration-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/configuration-icon-white.png");
}


.services-icon {
    background-image: url("../../../../assets/img/icons/products-icon.png");
}

.services-icon:focus {
    background-image: url("../../../../assets/img/icons/products-icon-white.png");
}

.services-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/products-icon-white.png");
}

.referents-icon {
    background-image: url("../../../../assets/img/icons/referents-icon.png");
}

.referents-icon:focus {
    background-image: url("../../../../assets/img/icons/referents-icon-white.png");
}

.referents-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/referents-icon-white.png");
}

.categories-icon div.icon {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.1em;
    margin-right: 0.6em;
    background-image: url("../../../../assets/img/icons/categories-icon.png");
    background-size: 100%;
}

.categories-icon:hover div.icon {
    background-image: url("../../../../assets/img/icons/categories-icon-white.png");
}

.categories-icon:focus div.icon {
    background-image: url("../../../../assets/img/icons/categories-icon-white.png");
}

.categories-icon-active div.icon {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.1em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/categories-icon-white.png");
    background-size: 100%;
}



.right-arrow-icon div.arrow {
    margin-left: auto;
    height: 0.9em;
    width: 0.9em;
    margin-right: 0.8em;
    background-image: url("../../../../assets/img/icons/right-arrow-icon.png");
    background-size: 75% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.right-arrow-icon:hover div.arrow {
    background-image: url("../../../../assets/img/icons/right-arrow-white-icon.png");
}

.right-arrow-icon:focus div.arrow {
    background-image: url("../../../../assets/img/icons/right-arrow-white-icon.png");
}

.right-arrow-icon-active div.arrow {
    margin-left: auto;
    height: 0.9em;
    width: 0.9em;
    margin-right: 0.8em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/right-arrow-white-icon.png");
    background-size: 75% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.down-arrow-icon div.arrow {
    margin-left: auto;
    height: 0.9em;
    width: 0.9em;
    margin-right: 0.8em;
    background-image: url("../../../../assets/img/icons/down-arrow-icon.png");
    background-size: 100% 70%;
    background-position: center;
    background-repeat: no-repeat;
}

.down-arrow-icon:hover div.arrow {
    background-image: url("../../../../assets/img/icons/down-arrow-white-icon.png");
}

.down-arrow-icon:focus div.arrow {
    background-image: url("../../../../assets/img/icons/down-arrow-white-icon.png");
}

.down-arrow-icon-active div.arrow {
    margin-left: auto;
    height: 0.9em;
    width: 0.9em;
    margin-right: 0.8em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/down-arrow-white-icon.png");
    background-size: 100% 70%;
    background-position: center;
    background-repeat: no-repeat;
}

.bar-icon {
    height: 1.5em;
    width: 0.4em;
    margin-right: 0.6em;
    background-size: 100% 100%;
}

.bar-icon:hover div {
    height: 1.5em;
    width: 0.4em;
    margin-right: 0.6em;
    background-image: url("../../../../assets/img/icons/bar-icon.png");
    background-size: 100% 100%;
}

.invisible-bar-icon {
    height: 1.5em;
    width: 0.4em;
    margin-right: 0.6em;
    background-image: url("../../../../assets/img/icons/bar-icon.png");
    background-size: 100% 100%;
    visibility: hidden;
}

.schedule-icon {
    background-image: url("../../../../assets/img/icons/schedule-icon.png");
}

.schedule-icon:focus {
    background-image: url("../../../../assets/img/icons/schedule-icon-white.png");
}

.schedule-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/schedule-icon-white.png");
}

.sales-icon {
    background-image: url("../../../../assets/img/icons/sales-icon.png");
}

.sales-icon:focus {
    background-image: url("../../../../assets/img/icons/sales-icon-white.png");
}

.sales-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/sales-icon-white.png");
}

.bodega-icon {
    background-image: url("../../../../assets/img/icons/bodega-icon.png");
}

.bodega-icon:focus {
    background-image: url("../../../../assets/img/icons/bodega-icon-white.png");
}

.bodega-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/bodega-icon-white.png");
}

.providers-icon {
    background-image: url("../../../../assets/img/icons/provider-icon.png");
}

.providers-icon:focus {
    background-image: url("../../../../assets/img/icons/provider-icon-white.png");
}

.providers-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/provider-icon-white.png");
}

.bank-icon {
    background-image: url("../../../../assets/img/icons/bank-icon.png");
}

.bank-icon:focus {
    background-image: url("../../../../assets/img/icons/bank-icon-white.png");
}

.bank-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/bank-icon-white.png");
}

.task-icon {
    background-image: url("../../../../assets/img/icons/task-icon.png");
}

.task-icon:focus {
    background-image: url("../../../../assets/img/icons/task-icon-white.png");
}

.task-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/task-icon-white.png");
}

.igss-icon {
    background-image: url("../../../../assets/img/icons/igss-black.png");
}

.igss-icon:focus {
    background-image: url("../../../../assets/img/icons/igss-gray.png");
}

.igss-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/igss-gray.png");
}

.paragraph-string{
    white-space: normal;
    text-align: justify;
}

.oc-icon {
    background-image: url("../../../../assets/img/icons/order-icon.png");
}

.oc-icon:focus {
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
}

.oc-icon-active div {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
    background-size: 100%;
}

.gastos-icon {
    background-image: url("../../../../assets/img/icons/order-icon.png");
}

.gastos-icon:hover {
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
}

.gastos-icon:focus {
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
}

.gastos-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
}

.gastos-icon-active {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
    background-size: 100%;
}

.diagnostic-icon {
    background-image: url("../../../../assets/img/icons/order-icon.png");
}

.diagnostic-icon:hover div {
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
}

.diagnostic-icon:focus div {
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
}

.diagnostic-icon-active div {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/order-icon-white.png");
    background-size: 100%;
}

.consult-icon {
    background-image: url("../../../../assets/img/icons/consult-icon.png");
}

.consult-icon:hover div {
    background-image: url("../../../../assets/img/icons/consult-icon-white.png");
}

.consult-icon:focus div {
    background-image: url("../../../../assets/img/icons/consult-icon-white.png");
}

.consult-icon-active div {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/consult-icon-white.png");
    background-size: 100%;
}

.plan-icon {
    background-image: url("../../../../assets/img/icons/plan-icon.png");
}

.plan-icon:hover div {
    background-image: url("../../../../assets/img/icons/plan-icon-white.png");
}

.plan-icon:focus div {
    background-image: url("../../../../assets/img/icons/plan-icon-white.png");
}

.plan-icon-active div {
    height: 1.1em;
    width: 1.1em;
    margin-bottom: 0.2em;
    margin-right: 0.6em;
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/plan-icon-white.png");
    background-size: 100%;
}

/* Reportes */
.reports-icon {
    background-image: url("../../../../assets/img/icons/reports-icon.png");
}

.reports-icon:focus {
    background-image: url("../../../../assets/img/icons/reports-icon-white.png");
}

.reports-icon-active {
    color: white;
    text-decoration: none;
    background-image: url("../../../../assets/img/icons/reports-icon-white.png");
}