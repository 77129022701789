.section-title-label{
    color: #555252;
    /* font-size: 0.95em; */
}

.service-package-column{
    width: 20%;
    border-right: 2px solid #7f7f7f;
    margin-top: 2em;
    margin-bottom: 2em;
    overflow: hidden;
}

.service-package-column label{
    font-size: small !important;
}

.service-package-form-column{
    width: 80%;
}

.inside-card{
    border: 1px solid rgba(85, 82, 82, .3);
    border-radius: 10px;
    border: none !important; /* modificado por remaquetación */
    background: white;
}

.line-price{
    background: #dcdcdc;
    height: 3px;
    flex-grow: 1;
}

.line-patient{
    background: #dcdcdc;
    height: 3px;
}

.patient-label{
    min-width: max-content;
}

.package-items-title-row{
    background: #dcdcdc;
    width: 100%;
    padding: 0.25em;
    margin-top: 1em;
}

.package-item-row-container{
    width: 100%;
    min-width: 100% !important;
    display:bock;
    /* overflow: auto;
    height: 12em; */
}

.package-item-row{
    width: 100%;
    padding: 0.25em;
    margin-top: 0.1em;
    border-bottom: 1px solid #dcdcdc;
    align-content: center;
    align-items: center;
}

.package-item-title{
    color: #555252;
    text-align: center;
    width: 35%;
}

.package-item-title-c{
    color: #555252;
    text-align: center;
    width: 40%;
}

.package-small-item-title{
    color: #555252;
    text-align: center;
    width: 15%;
}

.package-small-item-title-c{
    color: #555252;
    text-align: center;
    width: 20%;
}

.package-item{
    width: 35% !important;
}

.package-small-item{
    width: 15%;
    align-self: center;
}

.package-item-c{
    width: 40% !important;
}

.package-small-item-c{
    width: 20%;
    align-self: center;
}

.quantity-input{
    border-radius: .8em !important;
    padding-bottom: .1em !important;
}

.quantity-input:disabled{
    border-radius: 0 !important;
    padding-bottom: .1em !important;
}

.action-img{
    max-width: 27px;
    max-height: 27px;
    margin: 0 .30rem !important;
    cursor: pointer;
}

.delete-item-package {
    border-radius: 100%;
    background: #555252;
    padding: 0.4em;
}

.add {
    border-radius: 100%;
    background: #459058;
    padding: 0.4em;
}

.expanded-prices-rows{
    color: #7f7f7f;
    background: #EBEFF2;
    border-bottom: 1px solid #dcdcdc;
    border-left: 1px solid #dcdcdc;
    border-right: 1px solid #dcdcdc;
}

.expanded-rows-title{
    font-size: 1.3em;
    border-top: 1px solid #dcdcdc;
}

/* Estilos remaquetación */

.image-tab{
    max-width: 60px !important;
    max-height: 60px !important;
}

.division-line{
    width:90%;
    border:solid 2px #dcdcdc;
}

.w-20{
    width:20% !important;
}

.w-15{
    width:15% !important;
}

/* De los tabs */
.recuadro{
    border: solid 2px orange !important;
}

.tab-payment{
    border: solid 2px gray;
    height: 45px;
    padding:5px;
    cursor: pointer;
    font-weight: bold;
}

/* del segundo estilo */
.tp2{
    border-radius: 0px !important;
}

.tp-inactive{
    color:white;
    background: #0a5995;
}

.tp-active{
    color: #0a5995;
    background: white;
    height: 48px;
}

.tp-inactive-2{
    font-size: 14px !important;
    color: #0a5995;
    background: white;
}

.tp-active-2{
    font-size: 14px !important;
    color:white;
    background: #0a5995;
}

.tab-comodin{
    border-bottom: solid 1px gray;
    z-index: 4;
    border-bottom-left-radius: 15px;
    height: 7px !important;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}

/* tab comodin */
.tc{
    border-bottom: solid 1px gray;
    z-index: 1;
    border-bottom-left-radius: 15px;
    height: 0px !important;
    border-bottom-left-radius: 0px !important;

    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);

}

.first-active{
    border-top-left-radius: 0px !important;
}

.tab-payment-content{
    margin-top: -6px;
    border-top: solid 1px white;
    border-left: solid 1px gray;
    border-right: solid 1px gray;
    border-bottom: solid 1px gray;

    border-radius: 10px;
    border-top-right-radius: 5px;
    height: 80%;
}

.tpc-2{
    margin-top: 0px !important;
    /*border: none !important;*/
}

.w-30{
    width: 30%;
}

.b-white{
    background: white;
}

.content-method-payments{
    padding: 2px 25px 2px !important;
    overflow-y: auto;
    overflow-x: hidden;
}
/* */

.hidden{
    position: absolute;
    visibility: hidden;
}

.add-button-r2{
    border-radius: 100%;
    background: #60c579;
    padding: 0.4em;
}

.delete-button-r2{
    border-radius: 100%;
    background: #df1d38;
    padding: 0.4em;
}
