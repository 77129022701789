.left{
    text-align: left;
}

.action-img{
    max-width: 25px;
    margin: 0 .30rem !important;
    cursor: pointer;
}

.edit {
    border-radius: 100%;
    background: #0a5995;
    padding: 0.4em;
}

.watch{
    border-radius: 100%;
    background: #60c579;
    padding: 0.4em;
}

.delete {
    border-radius: 100%;
    background: #df1d38;
    padding: 0.4em;
}

.print {
    border-radius: 100%;
    background: #0a5995;
    padding: 0.4em;
}

.set-account {
    border-radius: 100%;
    background: #0a5995;
    padding: 0.1em;
}

.receivable {
    border-radius: 100%;
    background: white;
}

.paid {
    padding: 1px;
    border-radius: 100%;
    background: white;
}

.add{
    padding: 0.4em;
    border: solid 2px #0a5995;
    border-radius: 100%;
    background: white !important;
}
