.delete-create-button{
    background: #b93535;
    border-radius: 0.5em;
    padding: 0.4em;
}

.add-create-button{
    background: #459058;
    border-radius: 0.5em;
    padding: 0.4em;
}

.record-action-img{
    width: 27px;
    height: 27px;
    cursor: pointer;
}
