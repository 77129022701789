#table-payment tbody tr td{
    font-weight: bold;
    font-size: 17px;
    color: #0a5995;
}

#table-tarjet{
    border-radius: 10px !important;
}


#table-tarjet .thead-tarjet{
    background: #516060 !important;
    margin-bottom: 50px !important;

}

#table-tarjet .form-control{
    max-width:130px !important;
}

#add-row{
    border:none !important;
}

#add-row td{
    height: 75px !important;
}

#table-tarjet2{
    border-radius: 10px !important;
}


#table-tarjet2 .thead-tarjet{
    background: #516060 !important;
    margin-bottom: 50px !important;

}

#table-tarjet2 .form-control{
    max-width:120px !important;
}

#content-confirm{
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 3px;
}

.pay-button{
    border: solid 1px #043357 !important;
    background: white !important;
    color: #043357 !important;
    height: 35px !important;
}

.pay-button:hover{
    border: 3px double #043357 !important;
}

#comodin-table .react-bs-table-pagination{
    /*padding: 1rem 2rem 2rem 1rem !important;*/
    padding: 1rem 2rem 0rem !important;
}
