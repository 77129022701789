.cl-titulo{
    color: #7f7f7f;
}

.cl-subtitulo{
    font-weight: bold;
    width: 80%;
    border-bottom: solid 1px #7f7f7f;
}

.cl-content{
    border-top: solid 2px #7f7f7f;
}

.cl-table-sm{
    margin-right: 10px;
}

.cl-table-sm tbody tr td{
    padding: none !important;
    width: 60px !important;
}

.cl-table-sm tr td label{
    font-size: 11px;
}


.cl-table-sm .cl-modal-input{
    width: 30px !important;
    margin: 0px !important;
}


.cl-table-sm .cl-radio-input{
    width: auto !important;
    margin: 0px !important;
}

.cl-table-sm .cl-sm-input{
    width: 50px !important;
    max-width: 50px !important;
    margin: 0px !important;
    height: 25px !important;
    font-size: 13px !important;
}

.cl-mini-input .form-control{
    width: 40px;
    height: 25px;
    border-radius: 5px;
    padding: 2px 5px 2px;
    font-size: 13px;
    margin-right: 10px;
}

.cl-labels-list{
    width: 85% !important;
}

.cl-labels-list label{
    color:#0a5995 !important;
    font-weight: bold;
}

.cl-input-background-group .form-control{
    width: 70px;
}
