.tag-r2-container{
    border: solid 2px #0a5995;
    padding: 5px;
    border-radius: 10px;
    overflow: hidden;
}

.tag-r2-input{
    border: transparent;
    outline: none;
    margin-left: 4px;
    padding:2px;
    width: 45%;
}

.tag-r2-element{
    background: #0a5995;
    padding: 2px 4px 2px;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 5px;
}

.tag-r2-close{
    background: white;
    color: #0a5995;
    font-size: 12px;
    border-radius: 10px;
    margin-left: 4px;
    display: inline-block;
    width: 15px;
    height: 15px;
    flex: auto;
    align-items: center;
    justify-content: center;
    padding: 2px;
    cursor: pointer;
}
