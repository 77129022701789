.text-black{
    color: black !important;
}

.back-green{
    background: #60c579 !important;
    border-radius: 50%;
    padding: 5px;
}

.annotations{
    width: 100% !important;
    border-radius: 15px !important;
    outline: none;
    padding: 10px;
}

.barra-cirujia-derecha{
    width: 25% !important;
}

.barra-cirujia-derecha-contraida{
    width: 2% !important;
}

.barra-cirujia-desplegable{
    background: #459058 !important;
    width: 9% !important;
    height: 25% !important;
    cursor: pointer;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.barra-cirujia-contenido{
    background: #516060 !important;
    width: 90% !important;
    height: 100%;
}

.barra-cirujia-contenido span{
    color: white !important;
}

.barra-cirujia-contraida{
    display: none;
    width: 1% !important;
}

.barra-cirujia-desplegable-contraida{
    background: #459058 !important;
    width: 100% !important;
    height: 25% !important;
    cursor: pointer;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.decripcion-cirujia{
    width: 75% !important;
}

.decripcion-cirujia-expandida{
    width: 95% !important;
}

.title-size{
    font-size: 16px !important;
}

.content-subtitle-size{
    font-size: 13px !important;
}

.content-text-size{
    font-size: 12px !important;
}

.data-barra{
    border-bottom: solid 1px white;
    width: 95% !important;
}

.table-surgery-content{
    height: 300px !important;
    overflow-x: hidden;
    overflow-y: auto;
}
