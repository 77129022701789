.coupon{
	background-image: url('../../../../assets/img/icons/cupon.png') !important;
	background-position:center;
	background-repeat:no-repeat;
	background-size:cover;
    height:4.5cm;
    width:7.5cm;
    border:solid 1px rgba(0,0,0,0.2);
    margin:5px;
}

.coupon .amount{
    font-size: 15px;
    text-align: center;
    max-width: 100%;
    color: black;
    text-decoration: underline;
    font-weight: bold;
}

.coupon .code{
    font-size: 15px;
    max-width: 100%;
    color: black;
    text-transform: uppercase;
    text-decoration: underline;
    font-weight: bold;
}

.coupon .service{
    font-size: 12px;
    text-align: justify !important;
    text-align-last: center !important;
    word-wrap: break-word;
    margin-left: 1.6cm;
    width: 145%;
    max-height: 28%;
    /*color: #7B7B7B;*/
    color: black;
}

.coupon .general{
    text-align: center;
    word-wrap: break-word;
    margin-left: 1cm;
    /*border-width: 2px;
    border-style: solid;
    border-color: black;*/
}

#separacion::before, #separacion::after{
    content:"\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0";
    text-decoration-line: line-through;
}

.coupon-separation{
    font-size: 9px;
    /*color: #7B7B7B;*/
    color: black;
}

.coupon .expiration{
    font-size: 13px;
    /*color: #7B7B7B;*/
    color: black;
}

.coupon .hasta{
    font-size: 11px;
    /*color: #7B7B7B;*/
    margin-bottom: 0px !important;
    color: black;
}

.coupon .name{
    font-size: 10px;
    /*color: #7B7B7B;*/
    color: black;
}

.anulate-icon{
    border-radius: 100%;
    background: #df1d38;
    padding: 0.4em;
}

@media print {
    .coupon {
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important; 
    }
}