@media only screen and (max-width: 768px) {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

@media only screen and (min-width: 768px) {
    .form-body-width{
        width: 45em !important;
    }
}

table.table.table-hover.table-bordered {
    margin-bottom: 0;
}

table.table.table-hover.table-bordered {
    border: none;
}