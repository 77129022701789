@import "./configs/color.css";

.react-bs-container-header {
    background: $gray-g;
    /* text-transform: uppercase; */
    /* background: rgba(20,137,169,1);
    background: -moz-linear-gradient(left, rgba(20,137,169,1) 0%, rgba(69,49,93,1) 100%);
    background: -webkit-gradient(left top, right top, color-stop(0%, rgba(20,137,169,1)), color-stop(100%, rgba(69,49,93,1)));
    background: -webkit-linear-gradient(left, rgba(20,137,169,1) 0%, rgba(69,49,93,1) 100%);
    background: -o-linear-gradient(left, rgba(20,137,169,1) 0%, rgba(69,49,93,1) 100%);
    background: -ms-linear-gradient(left, rgba(20,137,169,1) 0%, rgba(69,49,93,1) 100%);
    background: linear-gradient(to right, rgba(20,137,169,1) 0%, rgba(69,49,93,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#b93535', GradientType=1 ); */
}
.table-bordered th{
    padding-left: 2em;
}
.table-bordered td{
    padding-left: 3em;
}
.table-bordered th, .table-bordered td {
    border: 0 !important;
}
.react-bs-container-body tr:nth-child(even){
    /* background-color: $gris; */
}
.no-striped .react-bs-container-body tr:nth-child(even){
    background-color: blue;;
}
.react-bs-container-body{
    color: $gray-g;
    font-family: Ubuntu;
    font-size: small;
    font-weight: bold;
}
.react-bs-table-bordered{
    border: none !important;
    border-radius: 0 !important;
}
.react-bs-table-tool-bar {
    background-color: ;
    border-radius: 5px;
    padding: 8px 10px 10px 10px;
}
.react-bs-table{
    /* font-style: italic; */
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;

}
.td-column-string-example {
    width: 250px !important;
}
.td-header-string-example{
    width: 250px !important;
}
.td-column-descripcion-gastos {
    width: 50% !important;
    min-width: 250px !important;
}
.td-header-descripcion-gastos{
    width: 50% !important;
    min-width: 250px !important;
}
.react-bs-table-pagination{
    padding: 3.5rem 2rem 2rem 0rem;
    .react-bootstrap-table-page-btns-ul{
        margin: 0;
    }
}
.react-bs-table-container {
    font-size: 13px !important;
    .react-bs-table table td, .react-bs-table table th {
        text-overflow: unset;
        overflow-wrap: break-word;
        width: 130px;
    }
    .react-bs-table-pagination div{
        display: flex !important;
        margin-top: 0 !important;
        align-items: center;
        ul {
            margin-left: auto;
        }
    }   
}
.strikeout td:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 2px solid red;
    width: 100%;
}

/* Paginaciones */
.page-item:last-child .page-link{
    border-top-right-radius: .50rem;
    border-bottom-right-radius: .50rem;
}
.page-link{
    color: $dark-gray;
    border: 1px solid rgba(85, 82, 82, .3);
}
.page-link:hover{
    color: $dark-gray;
}
.page-link:focus{
    color: $dark-gray;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.pagination{
    font-family: Ubuntu;
    font-weight: bolder;
}
.page-item:first-child .page-link {
    border-top-left-radius: .50rem;
    border-bottom-left-radius: .50rem; 
}

.page-item.active .page-link {
    z-index: 1;
    color: white;
    background-color: $blue;
    border-color: $blue;
}

.sin-borde-top {
    border-top: 0px !important;
}

.tabla-70 {
    .react-bs-container-body{
        max-height: 70vh !important;
    }
}
.tabla-5p .react-bs-container-body {
    max-height: 31vh !important;
}
.tabla-103{
    .react-bs-container-body{
        max-height: 135vh !important;
    }
}
.resumen-cf{
    .react-bs-table {
        font-style: italic;
        /* border-bottom-left-radius: 20px !important; */
        /* border-bottom-right-radius: 20px !important; */
        /* border: 3px solid #ebebeb !important; */
        border: 0px !important;
    }
    .react-bs-container-header {
        border-top-left-radius: 1em !important;
        border-top-right-radius: 1em !important;
    }
}
/**
* Para quitar el scroll horizontal de la tabla
*/
.tabla-auto{
    .table-bordered {
        min-width: auto !important;
    }
}

.tabla-adentro .react-bs-container-header table thead {
    color: #fff;
    text-transform: uppercase;

    background-color: $gray;
}


/*
    header gris
*/
.tabla-gris .react-bs-container-header {
    background: $gray !important;
    color:  !important;
}

.tabla-gris .react-bs-table{
    border: none;

}
.tabla-gris .header-tabla{
    background:  !important;
    color:  !important;
    height: 43px !important;
}
@media only screen and (max-width: 576px) {
    .td-column-descripcion-gastos {
        width: 250px !important;
    }

    .tabla-103{
        .react-bs-container-body{
            max-height: 60vh !important;
        }
    }
}

tr{
    border-bottom: 1px solid rgba(85, 82, 82, .3);
}

tr.clickable{
    cursor: pointer;
}

.borde-derecho .react-bs-table{
    border-top-right-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    border: 3px solid red !important;
}

.borde-derecho .react-bs-container-header {
    border-top-right-radius: 1em !important;
    background: $gray;
}

thead {
    font-size: medium;
    color: white !important;
}

.table-bordered td:focus{
    outline: none;
}

.table-decoration {
    border-top: 1px solid rgba(85, 82, 82, .3);
    border-radius: 10px;
    background: white;
    -webkit-box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.2);
    box-shadow: 1px 4px 6px 2px rgba(0,0,0,0.2);
    margin-bottom: 0.6em;
}

.top-title {
    border-bottom: 1px solid rgba(85, 82, 82, .3);
    font-family: Ubuntu;
    font-size: medium;
    font-weight: bold;
    color: #516060;
    border-radius: 10px 10px 0px 0px;
    padding: 1em 0em 1em 2.5em;
}

.top-title-banks {
    border-bottom: 1px solid rgba(85, 82, 82, .3);
    font-family: Ubuntu;
    font-size: medium;
    font-weight: bold;
    color: #516060;
    border-radius: 10px 10px 0px 0px;
    padding: .5em 0em .5em 1.3em;
}

.top-title-toolbar {
    /* border-bottom: 1px solid rgba(85, 82, 82, .3); */
    font-family: Ubuntu;
    font-size: small;
    font-weight: bold;
    color: #516060;
    border-radius: 10px 10px 0px 0px;
    margin: .7em .3em .7em 0em;
}

.marg-left{
    margin-left: 2.5em;
    margin-bottom: 2em;
}

.table-dashboard {
    text-transform: none;
    border: 1px solid $gray-g;
    -webkit-box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
    box-shadow: 1px 2px 2px 1px rgba(0,0,0,0.2);
}
.table-dashboard tr {
    border: 1px solid rgba(85, 82, 82, .3);
}
.table-dashboard th {
    padding: 0.50rem;
}
.table-dashboard td {
    padding: 0.25rem;
    border: 1px solid rgba(85, 82, 82, .3) !important;
}
.table-dashboard .content-fs-medium td {
    font-size: medium;
}