.content{
    width: 100%;
    background: gray;
}

.tab{
    width:50%;
    background: blue;
    height: 40px;
    text-align: center;
    padding:5px;
    font-size: 22px;
    font-weight: bold;
    color:white;
    text-transform: uppercase;
    cursor:pointer;
}

.right{
    border-radius: 22px 0px 0px 0px;
    -moz-border-radius: 22px 0px 0px 0px;
    -webkit-border-radius: 22px 0px 0px 0px;
    border: 0px solid #000000;
}

.left{
    border-radius: 0px 0px 22px 0px;
    -moz-border-radius: 0px 0px 22px 0px;
    -webkit-border-radius: 0px 0px 22px 0px;
    border: 0px solid #000000;
}

.tab .active{
    background: #f3f3f3 !important;
}
