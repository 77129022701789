.container-transcription-print{
    padding:15px 90px 15px 90px;
}

.container-transcription {
    padding:25px 125px 25px 125px;
}
.transcription-textarea-text{
    border: none;
    outline: none;
    height: 800px;
    text-align: justify;
    font-size: 17px;
}
.logo-transcription {
    width: 250px;
}
.parrafo-descripcion {
    margin-bottom: 0px;
}
.footer-transcription {
    background-color: white;
    padding:5px;
    color: black;
    line-height: initial;
}

.transcription-title{
    text-transform: uppercase;
}

.transcription-image-content{
    height: 800px;
}

.transcription-footer-cuadrito{
    width: 73px;
    /*height: 73px;*/
    height: 61px;
    background: #df1d38;
}

.transcription-footer-division-line{
    height: 5px;
    background: #df1d38;
}

.transcription-footer-line-shadow{
    height: 20px;
    background: gray;
    /*width: 99.3%;*/
    width: 100% !important;
    border: none !important;
}

.transcription-footer-puntero{
    position: relative;
    top: -35px;
    left: 96.4% !important;
}

.contenedor-transcripcion{
    display: none;
}