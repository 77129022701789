.cashbox{
    /* border:none !important;*/
    border-radius: 0px !important;
    border:none !important;
    border-right: solid 3px gray !important;
    margin-bottom: 15px;
    width: 20% !important;
    cursor: pointer;
    margin-bottom:5% !important;
    font-size: 15px;
}

.card-title{
    margin: 0px !important;
    color: rgb(109, 108, 108);
    font-weight: bold !important;
}

.card-text{
    color: gray;
}

.card-body{
    padding-top:2% !important;
    padding-bottom:2% !important;
}

.content-cashbox{
    padding:25px !important;
    padding-right: 10% !important;
    padding-left:10% !important;
}

.separator{
    border:         none;
    border-left:    2px solid hsla(200, 10%, 50%,100);
    height:         100vh;
    width:          1px;
}

.box-image{
    max-width: 100px !important;
    max-height: 100px !important;
    margin: auto;
}

.cashbox-container{
    padding: 10px;
    padding-left: 60px;
    padding-right: 60px;
}

.my-title{
    margin-top:1%;
    margin-bottom:15px;
    color:gray;
    margin-bottom:3%;
}

.my-title h3{
    font-weight: bold;
}

.separator {
  border-left: 2px solid gray;
  height: auto;
}

.linkCashBox{
    text-decoration: none !important;
}

.addRemoveCashImage{
    margin-bottom: 5px;
    max-width: 60px !important;
}

.secciones{
    padding:20px !important;
}

.contenedor-seccion{
    padding:25px;
}

/* For center list group in the oppeningBox.js */
/* for center ul */
.list-group-center{
    margin:auto;
    margin-top:2%;
}

/* For the active item in Ul list */
.active-cashbox{
    background: #368E31 !important;
}

/* For remove boreder en items list */
.list-group-item{
    border: none !important;
}

.seccion{
    padding:20px !important;
    border:solid 1px gray;
    border-radius: 10px;
}

.seccion-button-closeBox{
    text-align: center;
    border:none !important;
    margin-top: 30px;
}

.secciones .list-group .list-group-item{
    border:none !important;
}

.secciones .list-group .active{
    background: green !important;
}

.subtitle{
    color:gray !important;
    font-weight: 600 !important;
}

.input-match{
    height: 25% !important;
    width: 60% !important;
    padding:2px;
    text-align: center;
}

.icono-moneda{
    width:60%;
    margin:auto;
    margin-bottom:15px;
    float:center !important;
}

.tabla-cuadre{
    border: none !important;
}

.dinero-de-mas{
    color:green;
    font-weight: bold;
}

.dinero-faltante{
    color:red;
    font-weight: bold;
}

.modal-footer{
    justify-content: center;
    display: flex;
}

.btn-modal{
    margin:auto;
}

.modal-title{
    margin:auto;
}

.input-modal{
    height: 80%;
    border-radius: 3em;
    border: 0.15em solid #dcdcdc;
    width: 10em;
    outline:none;
    padding:5px;
    color: #3B649C;
    font-size: 15px;
}

.input-coin {
    position: relative;
    color: #3B649C;
    padding:2px;
    font-size: 15px;
}
.input-coin.left input {
    padding-left:25px;
}
.input-coin.right input {
    padding-right:18px;
    text-align:end;
}

.input-coin:before {
    position: absolute;
    top: 0px;
    content:"Q.";
}
.input-coin.left:before {
    left: 10px;
}
.input-coin.right:before {
    right: 5px;
}

.textarea-modal{
    border-radius: 12px;
    outline:none;
    padding:20px;
    width:100%;
}

.toggle-button-modal{
    width: 80% !important;
    font-size: 12px !important;
}

.table-resume thead tr{
    /* background: #459058 !important; */
    background: green !important;
}

.table-resume tbody tr td{
    border:solid 1px white !important;
}

.table-resume tbody tr th{
    border:solid 1px white !important;
}

.table-resume-category thead tr{
    /* background: #459058 !important; */
    background:#7F7F7F !important;
}

.table-resume-category tbody tr td{
    border:solid 1px white !important;
}

.table-resume-category tbody tr th{
    border:solid 1px white !important;
}
