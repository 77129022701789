.background{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: white;
    background-image: url("../../../../assets/img/login_back.png");
    background-size: 65% 100%;
    background-position: left center;
    background-repeat: no-repeat;
    align-items: center;
}

.logo img {
    height: 100px;
    width: 450px;
    margin-bottom: 1em;
}

.logo-sm img {
    margin-top: 1em;
    height: 70px;
    width: 315px;
}

.login-form{
    margin-left: 1em;
    color: #516060;
    font-size: medium;
    font-weight: bold;
}

.login-form img {
    height: 17px;
    width: 17px;
    margin-left: 1em;
    margin-bottom: 0.5em;
}


.login-form label {
    margin-left: 0.3em;
    margin-bottom: 0px;
}