.titulo-detalle-arqueo{
    color: #516060 !important;
    font-weight: bold !important;
    font-size: 15px !important;            
}

.separador-arqueo{
    border: solid 2px gray;
    border-radius: 3px;    
    margin-left: 15px;
    margin-right: 15px;
}

.tbl-arqueo .titulo{
    color: #516060 !important;
    font-weight: bold !important;
    font-size: 15px !important;    
}

.tbl-arqueo{    
    border-radius: 10px !important;
}

.tbl-arqueo tr{
    border: solid 1px white !important;
}

.tbl-arqueo tr th{ 
    border: none !important;
}

.tbl-arqueo tr td{ 
    border: none !important;
}

.arqueo-encabezado{
    background: #516060 !important;
    border-radius: 5px !important;
}
.fac-red{
    background: #9c020a !important;
    border-radius: 5px !important;
}
.borde-izquierdo{    
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.borde-derecho{    
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.link-retorno{
    color: #0A5995;
    font-weight: bold;
}

.right-text{
    width: 49%;
    float: left;
    text-align: right;
}

.ident{
    text-indent: 30px;
}