@import './configs/color.css';
@import url('https://fonts.googleapis.com/css?family=Ubuntu');

button:focus{
    outline: 1px auto;
    outline-color: transparent;
}
button img{
    margin-right: 5px;
}
.btn-estadocuenta{
    width: 170px;
    height: 37px;
}
.btn-normal {
    width: auto !important;
    min-width: 0 !important;
}
.btn-xs{
    font-size: 1em;
    height: 30px;
    padding: 0.1rem 0.75rem;
    min-width: 10px !important;
}
.btn {
    min-width: 120px;
    font-family: Ubuntu;
    font-size: medium;
}
.btn-small {
    cursor: pointer;
    border-radius: 10%;
    padding: .2rem;
    min-width: 85px !important;
    font-size: small !important;
    color: white !important;
}
.btn-force {
    border-radius: 2rem !important;
    min-width: 120px !important;
    font-weight: bold !important;
}
.btn-link{
    background-color: transparent !important;
    color: ;
}
.btn-link:hover{
    background-color: transparent !important;
    color: ;
}
.btn-link:active{
    background-color: transparent !important;
    color: ;
}

.btn-gray{
    background-color:  !important;
    color: ;

}
.btn-gray:hover{
    background-color:  !important;
    color: ;
}
.btn-gray:active{
    background-color:  !important;
    color: ;
}

/* ESTILO DE BOTON PRIMARIO*/
.btn-primary{
    background-color: $blue !important;
    color: white !important;
    border: 0px !important;
}
.btn-primary:hover{
}
.btn-primary:active{
    background-color: $dark-blue !important;
    border: 0px;
}
.btn-primary:focus{
    background-color: !important;
    border: 0px;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: none;
}
.btn-primary:focus, .btn-primary.focus {
    box-shadow: none;
}
/* ESTILO DE BOTÓN SECUNDARIO*/
.btn-secondary{
    background-color: !important;
    color: !important;
    border: 0px !important;
}
.btn-secondary:hover{
    background-color: !important;
    color: white !important;
    border: 0px !important;
}
.btn-secondary:active{
    background-color: !important;
    border: 0px !important;
}
.btn-secondary:focus{
    background-color: !important;
    border: 0px !important;
}

.btn-danger{
    background-color: ;
    border-color: transparent;
    color: #ffffff;
}
.btn-danger:hover{
    background-color: !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-danger:active{
    background-color: !important;
    border-color: transparent !important;
    color: #ffffff;
}

.btn-success{
    background-color: ;
    border-color: transparent;
    color: white;
}
.btn-success:hover{
    background-color: !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-success:active{
    background-color: !important;
    border-color: transparent !important;
    color: white;
}

.btn-success-outline{
    background-color: transparent;
    color: ;
    border: 2px solid ;
}
.btn-success-outline:hover{
    background-color: ;
    border-color: transparent;
    color: ;
}
.btn-success-outline:active{
    background-color: transparent;
    border-color: ;
    border: 2px solid ;
}


.btn-rosado{
    background-color: ;
    border-color: transparent;
    color: #ffffff;
}
.btn-rosado:hover{
    background-color: !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-rosadoo:active{
    background-color:  !important;
    border-color: transparent !important;
    color: #ffffff;
}


.btn-celeste{
    background-color: ;
    border-color: transparent;
    color: #ffffff;
}
.btn-celeste:hover{
    background-color:  !important;
    border-color: transparent !important;
    color: #ffffff;
}
.btn-celeste:active{
    background-color: !important;
    border-color: transparent !important;
    color: #ffffff;
}


.btn-blanco{
    background-color: white;
    border-color: transparent;
    color: ;
}
.btn-blanco:hover{
    background-color:  !important;
    border-color: transparent !important;
    color: white;
}
.btn-blanco:active{
    background-color: white;
    border-color: transparent;
    color: ;
}
.btn-tabla{
    border-radius: 5px;
    height: 28px;
    width: 28px;
    font-weight: bold;
    text-transform: uppercase;
}
/* .btn-info{
  background-color: ;
  border-color: transparent !important;
  color: white;
}
.btn-info:hover{
  background-color: ;
  border-color: transparent !important;
}
.btn-info:active{
  background-color: ;
  border-color: transparent !important;
}
.padding-0{
    padding: 0 !important;
}
.btn a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 6px 12px;
} */
.btn-s {
    border-radius: 2rem;
    min-width: 121px;
    height: 28px;
    font-weight: bold;
    padding: 0.315rem 0.75rem;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    font-size: .8rem;
    line-height: 1.5;
}
.btn-s:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:focus {
    box-shadow: 2.5px 2.5px 0px #DF1D38;
}
