.color-verde span{
    color: green !important;
    font-weight: bold;
}

.color-rojo span{
    color: red !important;
    font-weight: bold;
}

.optics-order-table-head thead{
    background: gray;
}

.contenedor-receta{
    display: none;
}

.avl-verde{
    color: #3e9910 !important;
    font-weight: bold;
}

.avl-rojo{
    color: #cb3821 !important;
    font-weight: bold;
}

.avl-azul{
    color: #3b5d83 !important;
    font-weight: bold;
}

.impresion-txt-negrita * {
    font-weight: bold!important;
}
.width-full span{
    width: 100%;
}