@import './configs/color.css';

@import url('https://fonts.googleapis.com/css?family=Ubuntu');
/* input
{
    height: 100px;
    width: 100px;
    box-shadow:inset 0 3px 0 0 rgba(0, 0, 0, 0.1);
} */
.border-trasparent{
    border: transparent !important;
}


.no-border{
    border: none !important;
}

.form-control:disabled, .form-control[readonly] {
    background-color: whitesmoke;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 0;
    border-color: gray;
    /* color: red; */
}

.form-control:disabled:active, .form-control[readonly] {
    background-color: whitesmoke;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 0;
    border-color: gray;
    /* color: red; */
}

.form-control:disabled:focus, .form-control[readonly] {
    background-color: whitesmoke;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 0;
    border-color: gray;
    /* color: red; */
}
.form-control {
    border-radius: 3em;
    border: 0.15em solid $dark-smoke;
    /* height: 2.3em; */
    width: 17em;
}

.form-month {
    width: 9.5em !important;
    font-weight: 100 !important;
}

.form-year {
    width: 7em !important;
    font-weight: 100 !important;
}

.form-control:focus {
    border: 0.1em solid $blue;
    box-shadow: none;
    -webkit-box-shadow: none;
}


.form-control:active {
    border: 0.1em solid $blue;
    box-shadow: none;
    -webkit-box-shadow: none;
}

.form-control-patient {
    border-radius: 3em;
    border: 0.15em solid $dark-smoke;
    /* height: 2.3em; */
    width: 23em;
}

.category-prices-labels label{
    font-size: small;

}

.category-prices input{
    border-radius: 3em;
    border: 0.15em solid $dark-smoke;
    height: 1.8em;
    width: 12em;
}


.service-price-discount{
    width: 17em;
}

.appointment-hour-input {
    width: 6.5em;
}

.appointment-hour-input input{
    width: 6.5em;
}

.service-price-input{
    width: 7.5em;
}

.service-price-input input{
    width: 7.5em;
}

.service-discount-input{
    width: 7.5em;
    /* width: 6em; */
}

.service-discount-input input{
    width: 7.5em;
    /* width: 6em; */
}

.category-label-width {
    width: 5em;
}

.category-prices input:active{
    border: 0.1em solid $blue;
    box-shadow: none;
    -webkit-box-shadow: none;
}


.category-prices input:focus{
    border: 0.1em solid $blue;

}

.form-group label {
    margin-top: 0px;
    font-weight: bold;
    font-size: 0.8rem;
    color: ;
}

.label-radio {
    padding: 0 10px;
}

.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .custom-select:invalid, .custom-select.is-invalid {
    border-color: ;
}
.invalid-feedback {
    display: none;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: ;
}


/* Configuración de selects*/
select {
    border-radius: 3em;
}
.Select-control{
    border-radius: 3em;
    border: 0.1em solid ;
    height: calc(2.25rem + 4px);
}
.Select-menu-outer {
    display: block !important;
    border-bottom-right-radius: 1em !important;
    border-bottom-left-radius: 1em !important;
    background-color: #fff;
    border: 1px solid  !important;
    border-top-color: #e6e6e6;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    box-sizing: border-box;
    margin-top: -1px;
    max-height: 200px;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    -webkit-overflow-scrolling: touch;
}
.Select-option:last-child {
    border-bottom-right-radius: 1em !important;
    border-bottom-left-radius: 1em !important;
    border-bottom: 1px solid  !important;
}
.Select-menu{
    border-bottom-right-radius: 1em !important;
    border-bottom-left-radius: 1em !important;
    border-bottom: 1px solid  !important;
}
.Select-option.is-selected {
    color: ;

    background-color:  !important;
    /* Fallback color for IE 8 */
    /* background-color: rgba(0, 126, 255, 0.04); */
}
.Select-option:hover {
    background-color:  !important;
    color:  !important;
}
.Select.is-searchable.is-focused:not(.is-open) > .Select-control {
    border: 0px;
    border-color:  !important;
}
.Select-value{
    font-weight: normal !important;
    color: #aaa !important;
    padding-left: 10px !important
}
.select-reset > div{
    background-color: transparent !important;
}

.is-invalid .react-select__control {
    border: 0.1em solid $red !important;
}


.Select-control:hover{
    -webkit-box-shadow: 0 0px 0 rgba(0, 0, 0, .06);
}
.Select-option.is-focused{
    background-color: white;
}
/*Configuración datepicker*/
.SingleDatePickerInput__withBorder {
    border-radius: 1em !important;
    border: 0.1em solid ;
}
.DateInput{
    background: transparent !important;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: ;
    border: 1px double ;
    color: #fff;
}

.DayPickerKeyboardShortcuts_show__bottomRight{
    display: none;
}
.DateInput_input__focused {
    border-radius: 1em !important;
}
.DateInput_input{
    border-radius: 1em !important;
    font-size: 1rem !important;
}
.is-invalid .SingleDatePickerInput{
    border-color: ;
}

/* Radio buttons*/
.form-check label{
    margin-right: 4px !important;
}

/*React-Select v2.1.1*/
.react-select__control{
    height: 100% !important;
}
.css-1hwfws3.react-select__value-container.react-select__value-container--has-value {
    height: 100%;
}
.css-1hwfws3.react-select__value-container{
    height: 100%;
}

.css-s9gsvw.react-select__menu {
    border-radius: 1em;
    margin: 0;
}

.css-11unzgr.react-select__menu-list {
    padding: 0;
}

.css-74da4x.react-select__option.react-select__option--is-focused{
    border-radius: 3em;
    background: #dcdcdc;
}

.css-1qooc60.react-select__option.react-select__option--is-focused.react-select__option--is-selected{
    border-radius: 3em;
}

.css-1qooc60.react-select__option.react-select__option--is-selected{
    border-radius: 3em;
}

.css-1u21krd.react-select__control.react-select__control--is-focused{
    border-radius: 3em !important;
}

/* .react-select__control{
    height: 100%;
    display: felx;
    border: none !important;
    background: transparent !important;
}

.react-select__value-container{
    display: flex;

    align-items: flex-end
}
.react-select__single-value{
    display: flex !important;
}
.react-select__value-container{
    height: 100%;
    display: flex;
    align-content: flex-start;
}

.react-select__indicators{
    height: 100%;
}

.react-select__single-value{
    display: flex;
    align-items: center;
}

.react-select__input{
    height: 10px !important;
}

.react-select__placeholder{
    display: flex;
    height: 100%;;
} */

/* !!!!!!!!!!!!!!!!!!!!     RADIO BUTTON     !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */

/*Colores del radiobutton*/
input[type='radio'] {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    outline: none;
    background: white;
    border: 2px solid $blue !important;
}

input[type='radio']:disabled {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 100%;
    outline: none;
    background: white;
    border: 2px solid $gray !important;
}

.radio label{
    color: $gray-g;
}

input[type='radio']:before {
    content: '';
    display: block;
    width: 65%;
    height: 65%;
    margin: 20% auto;
    border-radius: 100%;
}

input[type="radio"]:checked:before {
    background: $blue;
}

input[type="radio"]:checked {
    border-color: $blue;
    background: white;
}

/* !!!!!!!!!!!!!!!!!!!!     RADIO BUTTON ARRIBA    !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */


/*  Checkbox */

input[type='checkbox'] {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    border: 3px solid gray;
}

input[type='checkbox']:before {

    display: block;


}

input[type="checkbox"]:checked:before {
    content: '✔';
    position: absolute;
    height: 15px;
    width: 15px;
    top:0;
    left: 2px;
    color: #ffffff;
    display: inline-block;
    font-size: .8em;
    background: ;

}

input[type="checkbox"]:checked {
    background: ;
    border-color: ;
}
.role {
    margin-right: 80px;
    margin-left: 20px;
    font-weight: normal;
}

.checkbox label {
    /* margin-bottom: 20px !important; */
}

.roles {
    margin-bottom: 40px;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #000;
    transition: background-color 5000s ease-in-out 0s;
    background: #fff !important;
}

.css-vlouvg.react-select__control{
    border-radius: 3em;
    border: 0.15em solid $dark-smoke;
}

.react-select__input input{
    height: 100%;
}

.css-s9gsvw.react-select__menu {
    border-radius: 1.3em;
}

.css-1q0misi.react-select__control.react-select__control--is-disabled {
    background-color: whitesmoke;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 0;
    border-color: gray;
}


.css-142w6gm.react-select__single-value.react-select__single-value--is-disabled {
    color: #494f57 !important;
}

input:disabled {
    background-color: whitesmoke;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 0;
    border-color: gray;
    color: #494f57 !important;
}

input:disabled:active{
    background-color: whitesmoke;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 0;
    border-color: gray;
    color: #494f57 !important;
}

input[type="radio"]:disabled:active{
    background-color: white;
    /* opacity: 1; */
    border: transparent;
    border-bottom: 0.1em solid;
    border-radius: 100%;
    border-color: gray;
    color: #494f57 !important;
}

input[type="radio"]:checked:disabled:before {
    background: gray;
    border-radius: 100%;
}

.slim-input-height{
    height: 1.8em;
}

.slim-input-height div.css-vlouvg{
    min-height: 1.8em !important;
}

.slim-input-height div.css-vlouvg div{
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 5px;
}

.slim-input-height div.css-vlouvg div div{
    font-size: small;
    padding-left: 0px;
    padding-right: 0px;
}

.hour-small-tab-input{
    width: 5.2em;
}

.input-back-date input{
    width: 8em;
    text-align: center;
}

.justification-message-input{
    border-radius: 1.5rem;
    width: 26rem;
}

.justification-message-input-full-width{
    width: 100%;
}

.justification-message-input-full-width div{
    width: 100%;
}

.justification-message-input-full-width div div{
    width: 100%;
}

.justification-message-input-full-width div div textarea{
    border-radius: 1.5rem;
    width: 100%;
}

.modal-label-width{
    width: 9rem;
    /* text-align: center; */
}

.no-padding form{
    padding: 0;
}

.bbi-transaction{
    border-bottom: 1px solid rgba(85, 82, 82, .2);
}

.custom-ant-time-picker {
    width: 100% !important;
    border: none;
    height: 100%;
}

.custom-ant-time-picker input{
    width: 100%;
    border: transparent;
    border-radius: 3em;
    height: 100%;
    font-size: 1rem;
}






.c-checkbox,
.c-radio {
  margin-right: 4px;
}
.c-checkbox *,
.c-radio * {
  cursor: pointer;
}
.c-checkbox input,
.c-radio input {
  opacity: 0;
  position: absolute;
  margin-left: 0 !important;
}
.c-checkbox span,
.c-radio span {
  position: relative;
  display: inline-block;
  vertical-align: sub;
  width: 18px;
  height: 18px;
  border: 1px solid #ccc;
  text-align: center;
}
.c-checkbox span:before,
.c-radio span:before {
  margin-left: 1px;
}
.c-checkbox:hover span,
.c-radio:hover span {
  border-color: $blue;
}
.form-inline .c-checkbox span, .form-inline
.c-radio span {
  margin-left: 0;
}
.c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
.c-radio.c-checkbox-rounded span,
.c-radio.c-radio-rounded span {
  border-radius: 500px;
}

.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle;
}

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */
}
.c-checkbox input[type=checkbox]:checked + span:before,
.c-checkbox input[type=radio]:checked + span:before,
.c-radio input[type=checkbox]:checked + span:before,
.c-radio input[type=radio]:checked + span:before {
  color: #FFFFFF;
  opacity: 1;
  transition: color .3s ease-out;
}

.c-checkbox input[type=checkbox]:checked + span,
.c-checkbox input[type=radio]:checked + span,
.c-radio input[type=checkbox]:checked + span,
.c-radio input[type=radio]:checked + span {
  border-color: $blue;
  background-color: $blue;
}

.c-checkbox input[type=radio]:checked + span,
.c-radio input[type=radio]:checked + span {
  background-color: #fff;
}
.c-checkbox input[type=radio]:checked + span:before,
.c-radio input[type=radio]:checked + span:before {
  color: $blue;
}
.c-checkbox input[type=checkbox]:disabled + span,
.c-checkbox input[type=radio]:disabled + span,
.c-radio input[type=checkbox]:disabled + span,
.c-radio input[type=radio]:disabled + span {
  border-color: #ddd !important;
  background-color: #ddd !important;
}
.c-checkbox input[type=radio]:disabled + span,
.c-radio input[type=radio]:disabled + span {
  background-color: #fff !important;
}
.c-checkbox input[type=radio]:disabled + span:before,
.c-radio input[type=radio]:disabled + span:before {
  color: #ddd;
}

.va-color {
    color: #0a5995;
}