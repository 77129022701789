@import "color.css";

.gray-g {
    color: $gray-g;
}
.bg-blue {
    background-color: $blue;
}
.bg-bright-red {
    background-color: $bright-red;
}