.color-select{
    border: none !important;
    /*background: green !important;*/
    padding: 0px !important;
    outline: none !important;
    height: 50px !important;
    width: 250px !important;
    margin: 0px !important;
    /*overflow: hidden !important;*/
}

#select-color .css-v5lg7q{
    /*border: solid 2px purple !important;*/
    height: 10% !important;
    padding: 0px !important;
    margin: 0px !important;

}

#select-color .css-1hwfws3{
    /*border: solid 2px orange !important;*/
    height: 40px !important;
}

#select-color .css-1wy0on6{
    height: 10% !important;
    padding:0px !important;

}

#select-color .css-d8oujb{
    margin: 0px !important;
    padding: 0px !important;
}

#select-color .invalid-feedback {
    display: none;
    font-weight: bold;
    font-style: italic;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: ;
}

.color-list{
    border-radius: 25px;
    content: '""';
    width: 20px;
    height: 20px;
    display:block;
}
