.diagnostic-resume-textarea{
    border-radius: 12px;
    outline: none;
    padding: 5px 10px 5px 10px;
}

.dr-boton-enviar{
    width: auto !important;
    font-size: 12px !important;
}

.dr-table-procedures thead tr th{
    color: black;
    text-align: center;
}
